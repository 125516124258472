import React, { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { addField, FieldTitle } from 'ra-core';
import PropTypes from 'prop-types';

import {
  InputLabel, FormControl, withStyles, Select,
  MenuItem, Checkbox, ListItemText, makeStyles, Typography,
} from '@material-ui/core';
import { NO_FOUND_INDEX, ONE } from './constants';

const styles = {
  label: {
    position: 'relative',
  },
};

const useStyles = makeStyles(() => ({
  multiSelect: {
    width: 'inherit',
    flexWrap: 'wrap',
    paddingTop: 15,
    minHeight: 48,
  },
  chip: {
    margin: 2,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const RetailerFilterDropdownComp = (props) => {
  const {
    meta: { touched, error },
    label,
    id,
    source,
    resource,
    isRequired,
    pickupPoints,
    selectedPickupPoints,
    className,
  } = props;
  const [values, setValues] = React.useState([]);

  const classes = useStyles();

  const handleValueChange = (val) => {
    const { input: { onChange } } = props;
    const selectedValues = val.map(retailer => retailer.id);
    onChange(selectedValues);
  };

  const renderValue = selected => (
    <div className={classes.chips}>
      {selected.map(retailer => retailer.name).map((value, index) => (
        <Typography key={value} className={classes.chip}>
          {index === selected.length - ONE ? value : `${value}, `}
        </Typography>
      ))}
    </div>
  );


  const isChecked = retailerId => values?.map(item => item.id)
    ?.indexOf(retailerId) > NO_FOUND_INDEX;

  const onDataChanged = (data) => {
    const optedRetailers = data.target.value;
    setValues(optedRetailers);
    handleValueChange(optedRetailers);
  };

  useEffect(() => {
    let selectedValues = [];
    if (selectedPickupPoints?.length && pickupPoints?.length) {
      selectedValues = pickupPoints.reduce((acc, cur) => {
        if (selectedPickupPoints.filter(item => item === cur.id).length) {
          return [...acc, cur];
        }
        return acc;
      }, []);
      setValues(selectedValues);
    }
    /* eslint-disable-next-line */
  }, [selectedPickupPoints]);

  return (
    <FormControl
      error={!!(touched && error)}
      className={className}
      style={{ maxWidth: '350px' }}
    >
      {label !== '' && label !== false && (
        <InputLabel shrink htmlFor={id} className={classes.label}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
      )}
      <Select
        id="grouped-select"
        multiple
        value={values}
        className={classes.multiSelect}
        renderValue={renderValue}
        onChange={onDataChanged}
      >
        {
            pickupPoints && pickupPoints.map(retailer => (
              <MenuItem value={retailer} key={retailer.id}>
                <Checkbox checked={isChecked(retailer.id)} />
                <ListItemText primary={retailer.name} />
              </MenuItem>
            ))
          }
      </Select>
    </FormControl>
  );
};

const RetailerFilterDropdown = addField(withStyles(styles)(RetailerFilterDropdownComp));

RetailerFilterDropdown.propTypes = {
  input: PropTypes.object,
  source: PropTypes.string,
};

RetailerFilterDropdown.defaultProps = {
  addLabel: false,
  fullWidth: true,
};
export default RetailerFilterDropdown;
