import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { useLoading } from 'ra-core';
import { useParams } from 'react-router';

import HistoricSearchUi from '../../lib/components/HistoricSearchUi';

const CardHistoric = () => {
  const initialPage = 1;
  const perPage = 10;
  const { id } = useParams();
  const dataProvider = useDataProvider();
  const [data, setData] = useState(undefined);
  const [page, setPage] = useState(initialPage);
  const [total, setTotal] = useState(undefined);
  const [canPrev, setCanPrev] = useState(false);
  const [canNext, setCanNext] = useState(false);
  const loading = useLoading();
  const notify = useNotify();

  const handleQuery = () => {
    const variables = {
      pagination: {
        perPage,
        page,
      },
      filter: {
        loyaltyCardIds: [id],
      },
    };
    setData(undefined);

    dataProvider
      .getList('cardsHistory', { ...variables })
      .then((res) => {
        const { data: queryData, pagination, total: totalCount } = res;
        setData(queryData);
        setTotal(totalCount);
        setCanPrev(pagination?.prev !== null);
        setCanNext(pagination?.next !== null);
      })
      .catch((err) => {
        notify(err?.message, 'error');
      });
  };

  useEffect(() => {
    if (page && id) {
      handleQuery();
    }
    /* eslint-disable-next-line */
  }, [page, dataProvider]);

  return (
    <HistoricSearchUi
      data={data}
      isDetail="cards"
      loading={loading}
      total={total}
      canPrev={canPrev}
      canNext={canNext}
      page={page}
      setPage={setPage}
    />
  );
};

export default CardHistoric;
