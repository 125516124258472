import React, { useState } from 'react';
import {
  Button,
  useNotify,
  useTranslate, FileField, FileInput, SimpleForm,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl, Box,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';
import CardContent from '@material-ui/core/CardContent';
import UPLOAD_FILE_TO_STORAGE from '../../providers/mutations/uploadFileToStorage';
import {
  CSV_CONTRACTS_PATH,
  CSV_CONTRACTS_TEMPLATE_URL,
  CSV_EAN_TEMPLATE_URL,
  getBucketName
} from '../utils';
import client from '../../providers/client';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 132,
  },
}));

const CHOICES = {
  CONTRACT: { key: 'contract', filename: 'input_contracts.csv' },
  EAN: { key: 'ean', filename: 'input_eans.csv' },
};

const UploadContracts = () => {
  const [file, setFile] = useState();
  const [choice, setChoice] = useState(CHOICES.CONTRACT);
  const maxSize = 2000000;
  const [showDialog, setDialogue] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();
  const classes = useStyles();
  const globalFont = { fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif' };
  const megas = 1000000;
  const MegasSize = maxSize ? parseInt(maxSize, 10) / megas : false;

  const clearSource = () => {
    setDialogue(false);
  };

  const handleFileUpload = (file) => {
    setFile(file);
  };

  const handleError = () => {
    notify(translate('products.contracts.error'), 'error');
  };

  const handleSave = async () => {
    client.mutate(
      {
        mutation: gql`${UPLOAD_FILE_TO_STORAGE}`,
        variables: {
          file,
          path: `${CSV_CONTRACTS_PATH()}${choice.filename}`,
          bucket: getBucketName()
        }
      }).then((res) => {
        const { data } = res;
        const { uploadFileToStorage } = data;

        uploadFileToStorage
         ? notify(translate('products.contracts.success'), 'success')
         : handleError();
      })
      .catch(() => handleError());
    };

  return (
    <div style={{ width: '100%' }}>
      <CardContent>
        <h3 style={{ ...globalFont }}>
          {translate('products.contracts.title')}
        </h3>
        <Typography>
          {translate('products.contracts.choices.contract.name')}
          <FileField
            record={{ url: CSV_CONTRACTS_TEMPLATE_URL() }}
            source="url"
            title={translate('products.contracts.choices.contract.claim')}
            style={{ ...globalFont }}
          />
        </Typography>
        <Typography>
          {translate('products.contracts.choices.ean.name')}
          <FileField
            record={{ url: CSV_EAN_TEMPLATE_URL() }}
            source="url"
            title={translate('products.contracts.choices.ean.claim')}
            style={{ ...globalFont }}
          />
        </Typography>
        <Box />
        <FormControl style={{ flexDirection: 'row' }}>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => {
              setChoice(CHOICES.CONTRACT);
              return setDialogue(true);
            }}
            label={translate('products.contracts.choices.contract.button')}
          >
            <IconContentAdd />
          </Button>
          <Button
            className={classes.button}
            style={{ marginLeft: '5px' }}
            variant="outlined"
            onClick={() => {
              setChoice(CHOICES.EAN);
              return setDialogue(true);
            }}
            label={translate('products.contracts.choices.ean.button')}
          >
            <IconContentAdd />
          </Button>
        </FormControl>
      </CardContent>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => clearSource()}
        aria-label={translate('products.contracts.choices.contract.button')}
      >
        <DialogTitle>Add csv</DialogTitle>
        <DialogContent>
          <Typography><b>Format:</b> text/csv</Typography>
          {MegasSize && <Typography><b>Maximum weight</b> {MegasSize}Mb</Typography>}
          <Typography><b>The maximum number of EANs to process is</b> 20.000</Typography>
          <SimpleForm toolbar={null}>
            <FileInput
              onChange={file => handleFileUpload(file)}
              source="files"
              label={translate('products.contracts.choices.ean.button')}
              accept="text/csv"
            >
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleForm>
        </DialogContent>
        <DialogActions>

          <Button
            label="ra.action.save"
            disabled={!file}
            onClick={() => handleSave(file)}
          >
            <IconSave />
          </Button>
          <Button onClick={() => clearSource()} label="ra.action.cancel">
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadContracts;
