import React, { useEffect, useRef } from 'react';
import { RadioButtonGroupInput, TextInput, useDataProvider } from 'react-admin';
import { Box, Checkbox, FormControlLabel, Link } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import SunEditor from 'suneditor-react';

import { promotionFormStyles } from '../promotions/promotionFormStyles';
import { useForm, useFormState } from 'react-final-form';
import { getEnv } from '../../lib/utils';

const StaticForm = (props) => {
  const { record } = props;
  const { values } = useFormState();
  const form = useForm();
  const dataProvider = useDataProvider();
  const latestPosition = useRef(undefined);
  const defaultPosition = useRef(undefined);

  const {
    splitContents,
    setSplitContents,
    contentDesktop,
    setContentDesktop,
    contentMobile,
    setContentMobile,
  } = props;
  const classes = promotionFormStyles();

  useEffect(() => {
    if (!values?.position) {
      dataProvider.getList('staticContent')
        .then(res => {
          const { data } = res;

          if (data?.length) {
            data.forEach((dataItem) => {
              if (!latestPosition?.current || dataItem?.position >= latestPosition.current) {
                latestPosition.current = dataItem.position;
                defaultPosition.current = dataItem.position + 1;
              }
            });
          }
        });
    } /* eslint-disable-next-line */
  }, []);

  const buttonList = [
    ['undo', 'redo'],
    ['fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle', 'removeFormat'],
    ['outdent', 'indent', 'align', 'horizontalRule', 'lineHeight'],
    ['list', 'table', 'link', 'image', 'showBlocks', 'preview'],
  ];

  const handleChangeSplit = (event) => {
    setSplitContents(event.target.checked);
  };

  const baseUrl = () => {
    const env = getEnv();
    switch (env) {
      case 'production':
        return 'https://communaute-testerdesproduits.carrefour.fr/article/';
      case 'prepro':
        return 'https://communaute-testerdesproduits-preprod.carrefour.fr/article/';
      case 'develop':
      default:
        return 'https://tokywoky-spa.develop-public.shopadvizor.net/article/';
    }
  };

  useEffect(() => {
    if (values?.status !== 'PUBLISHED') {
      form.change('position', null);
    } else {
      if (defaultPosition.current && !values?.position) {
        form.change('position', defaultPosition.current);
      } else if (record?.position) {
        form.change('position', record?.position);
      }
    } /* eslint-disable-next-line */
  }, [form, values]);

  return (
    <>
      <div className={classes.mainRow}>
        <div className={classes.rowCol}>
          <div className={classes.normalBoxLite}>
            <div className={classes.sectionTitle}>Information</div>
          </div>
          <div className={classes.normalBoxColumn}>
            <Box className={classes.normalBoxLite}>
              <TextInput
                source="name"
                fullWidth
                label="Title"
                isRequired
              />
            </Box>
            <Box className={classes.normalBoxLite}>
              <TextInput
                source="alias"
                fullWidth
                label="Alias"
                helperText="This text will appear at the end of the page URL. It is recommended that it be in lowercase, without spaces and with a hyphen separating words."
                isRequired
              />
            </Box>
            {values?.alias && (
              <Box className={classes.normalBoxLite}>
                <LinkIcon style={{ marginRight: '10px' }} />
                <Link target="_blank" href={`${baseUrl()}${values.alias}`}>{baseUrl()}{values.alias}</Link>
              </Box>
            )}
          </div>
        </div>
        <div className={classes.rowCol}>
          <div className={classes.normalBoxLite}>
            <div className={classes.sectionTitle}>Status and Configuration</div>
          </div>
          <div className={classes.normalBoxLite}>
            <div className={classes.normalBoxLeft}>
              <Box>
                <RadioButtonGroupInput
                  source="status"
                  defaultValue="REJECTED"
                  choices={[
                    { id: 'PUBLISHED', name: 'Published (with visible link in footer)' },
                    { id: 'READ_ONLY', name: 'Published (not visible link in footer)' },
                    { id: 'REJECTED', name: 'Unpublished' },
                  ]}
                  fullWidth
                  hiddenLabel
                  isRequired
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={splitContents} onChange={handleChangeSplit} />}
                  label="Split contents (desktop and mobile)"
                />
              </Box>
            </div>
            <div className={classes.normalBoxRight}>
              <Box>
                <TextInput
                  source="position"
                  label="Order N#"
                  disabled={values?.status !== 'PUBLISHED'}
                  isRequired={values?.status === 'PUBLISHED'}
                  helperText={latestPosition.current ? `Highest order saved: ${latestPosition.current}` : null}
                />
              </Box>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.mainRow}>
        <div className={classes.fullRowCol}>
          <div className={classes.normalBoxLite}>
            <div className={classes.sectionTitle}>{splitContents ? 'Content for desktop' : 'Content'}</div>
          </div>
          <div className={classes.normalBoxFull}>
            <SunEditor
              setContents={contentDesktop}
              onChange={data => setContentDesktop(data)}
              setOptions={{ buttonList }}
              setDefaultStyle="font-family: Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif;height: auto;min-height: 400px;"
            />
          </div>
        </div>
      </div>

      {splitContents && (
        <div className={classes.mainRow}>
          <div className={classes.fullRowCol}>
            <div className={classes.normalBoxLite}>
              <div className={classes.sectionTitle}>Content for mobile</div>
            </div>
            <div className={classes.normalBoxFull}>
              <SunEditor
                setContents={contentMobile}
                onChange={data => setContentMobile(data)}
                setOptions={{ buttonList }}
                setDefaultStyle="font-family: Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif;height: auto;min-height: 400px;"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StaticForm;
