import React, { useState, useEffect } from 'react';
import {
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  useDataProvider,
  useTranslate,
} from 'react-admin';
import { useForm } from 'react-final-form';
import gql from 'graphql-tag';
import client from '../../providers/client';
import { uploadCouponCodesButton } from './uploadCouponCodesButtonStyles';

const INITIAL_FILE = 0;
const ZERO = 0;
const PROGRESS_COMPLETED_PERCENT = 100;
const PROGRESS_CHECK_DELAY = 3000;

const UploadCouponCodesButton = (props) => {
  const [file, setFile] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [percentage, setPercentage] = useState(undefined);
  const [fetchingCodes, setFetchingCodes] = useState(false);
  const [onLoadCheck, setOnLoadCheck] = useState(true);
  const { recordData } = props;
  const dataProvider = useDataProvider();
  const classes = uploadCouponCodesButton();
  const translate = useTranslate();
  const form = useForm();

  const GET_FILE_PROGRESS = gql`
    query couponCodesJobsProgress($promotionId: ID!) {
      couponCodesJobsProgress(promotionId: $promotionId) {
        percentCouponCodesProcessed
      }
    }
  `;

  const GET_PROMOTION_CODES = gql`
    query promotion($id: ID!) {
      promotion(id: $id) {
        id
        couponCodesStats {
          total
          distributed
          notDistributed
        }
      }
    }
  `;

  const parentProcessingHandler = (isProcessing) => {
    props.passChildProcessing(isProcessing);
  };

  const parentCodesHandler = (codesData) => {
    props.passChildCodes(codesData);
  };

  const getProcessCodesProgress = () => {
    if ((file?.name || percentage || onLoadCheck === true)
      && (recordData !== undefined && recordData.uuid.length > ZERO)) {
      client
        .query({
          query: GET_FILE_PROGRESS,
          fetchPolicy: 'no-cache',
          variables: {
            promotionId: recordData?.uuid,
          },
        })
        .then((res) => {
          const { data } = res;
          const { couponCodesJobsProgress } = data;
          const { percentCouponCodesProcessed } = couponCodesJobsProgress;

          if (onLoadCheck === false) {
            setPercentage(percentCouponCodesProcessed);
          } else {
            setOnLoadCheck(false);

            if (percentCouponCodesProcessed < PROGRESS_COMPLETED_PERCENT) {
              parentProcessingHandler(true);
              setPercentage(percentCouponCodesProcessed);
            }
          }
        })
        .catch((err) => {
          if (err.message.match(/A file with coupon codes must be uploaded/i)) {
            setOnLoadCheck(false);
          } else {
            setPercentage(`ERROR: ${err}`);
          }
        });
    }
  };

  const getPromotionCodes = () => {
    setFetchingCodes(true);

    client
      .query({
        query: GET_PROMOTION_CODES,
        fetchPolicy: 'no-cache',
        variables: {
          id: recordData.uuid,
        },
      })
      .then((res) => {
        const { data } = res;
        const { promotion } = data;
        const { couponCodesStats } = promotion;

        form.change('couponCodesStats.total', couponCodesStats.total);
        form.change('couponCodesStats.distributed', couponCodesStats.distributed);
        form.change('couponCodesStats.notDistributed', couponCodesStats.notDistributed);
        parentCodesHandler(couponCodesStats);
        setFetchingCodes(false);
      })
      .catch(() => {
        const couponCodesStats = {
          total: 'ERROR',
          distributed: 'ERROR',
          notDistributed: 'ERROR',
        };
        parentCodesHandler(couponCodesStats);
        setFetchingCodes(false);
      });
  };

  const handleOnChange = () => {
    setLoading(true);
    setError(false);
    parentProcessingHandler(true);
    const uploadedFile = document.getElementById('inputCSV').files[INITIAL_FILE];

    dataProvider.create('coupons', { id: recordData.uuid, file: uploadedFile })
      .then(() => {
        setFile(uploadedFile);
      }).catch((err) => {
        setLoading(false);
        setFile(undefined);
        setError(err?.message);
      });
  };

  const buttonText = () => {
    if (loading) {
      if (percentage !== undefined && percentage < PROGRESS_COMPLETED_PERCENT) {
        return translate('promotion.coupons.processing');
      }

      return translate('promotion.coupons.uploading');
    }

    if (fetchingCodes === true) {
      return translate('promotion.coupons.refreshing');
    }

    return translate('promotion.coupons.upload');
  };

  useEffect(() => {
    getProcessCodesProgress();
    /* eslint-disable-next-line */
  }, [file]);

  useEffect(() => {
    if (onLoadCheck && recordData !== undefined && recordData?.uuid?.length > ZERO) {
      getProcessCodesProgress();
    }
    /* eslint-disable-next-line */
  }, [recordData, onLoadCheck]);

  useEffect(() => {
    if (percentage !== undefined) {
      if (percentage < PROGRESS_COMPLETED_PERCENT) {
        setTimeout(getProcessCodesProgress, PROGRESS_CHECK_DELAY);
      } else {
        setLoading(false);
        parentProcessingHandler(false);
        getPromotionCodes();
      }
    }
    /* eslint-disable-next-line */
  }, [percentage]);

  return (
    <div className={classes.container}>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          component="label"
          fullWidth
          disabled={
          loading
            || onLoadCheck
            || (percentage !== undefined && percentage < PROGRESS_COMPLETED_PERCENT)
            || fetchingCodes}
        >
          {loading
          || (percentage !== undefined && percentage < PROGRESS_COMPLETED_PERCENT)
          || fetchingCodes
            ? (
              <CircularProgress size={20} thickness={1} className={classes.loadingCircle} />
            ) : null}
          {buttonText()}
          <input
            type="file"
            id="inputCSV"
            accept=".csv"
            hidden
            onChange={e => handleOnChange(e)}
          />
        </Button>
        {percentage
          ? (
            <div className={classes.progressBar}>
              <div className={classes.progressBarText}>
                {percentage < PROGRESS_COMPLETED_PERCENT
                  && (
                  <CircularProgress
                    size={11}
                    thickness={2}
                    className={classes.loadingCircleBar}
                  />
                  )}
                {percentage}%
              </div>
              <div className={classes.filledBar} style={{ width: `${percentage}%` }} />
            </div>
          ) : null}
        {file
          ? <p><span className={classes.fileLabel}>{translate('promotion.coupons.file')}</span> {file.name}</p> : null }
        {error ? <span className={classes.errorMessage}>{error}</span> : null}
      </div>

    </div>
  );
};


export default UploadCouponCodesButton;
