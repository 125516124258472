import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import {
  Button,
  Confirm,
  SimpleForm, TextInput, useDataProvider,
} from 'react-admin';
import { useNotify, useRefresh, useTranslate } from 'ra-core';


import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import IconSave from '@material-ui/icons/Delete';
import { Box } from '@material-ui/core';
import PRODUCTS from '../../providers/queries/products';
import client from '../../providers/client';


const ProductAssociation = (props) => {
  const { record } = props;
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [search, setSearch] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [associations, setAssociations] = useState(null);
  const [erasedProduct, setErasedProduct] = useState(null);
  const [loadProduct, setLoadProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const addItemIfNotExists = (item) => {
    const exists = selectedProducts.some(i => i.id === item.id);
    if (!exists) {
      setSelectedProducts([...selectedProducts, item]);
    }
  };
  const save = (p) => {
    const exists = selectedProducts.some(i => i.id === p.id);
    if (!exists) {
      const ean2Send = [...selectedProducts, p];
      setLoading(true);
      const input = {
        ean: record.id,
        associatedEans: ean2Send.map(product => product.ean),
      };
      dataProvider.update('productsAssociation', {
        data: { ...input },
      })
        .then(() => {
          refresh();
          notify(translate('resources.comments.status.success'), 'success');
          setLoading(false);
          setLoadProduct(null);
          addItemIfNotExists(p);
          setSearch('');
        })
        .catch((error) => {
          notify(error?.message, 'error');
          setLoading(false);
        });
    }
  };
  const deleteEanActions = (product) => {
    setOpen(true);
    setErasedProduct(product);
  };
  const deleteEan = () => {
    if (erasedProduct) {
      setLoading(true);
      const existsSelect = selectedProducts.some(i => i.id === erasedProduct.id);
      if (existsSelect) {
        const filter = selectedProducts.filter(p => p.id !== erasedProduct.id);
        const input = {
          ean: record.id,
          associatedEans: filter.map(p => p.ean),
        };
        dataProvider.update('productsAssociation', {
          data: { ...input },
        })
          .then(() => {
            setSelectedProducts(filter);
            notify(translate('resources.comments.status.success'), 'success');
            setLoading(false);
            setOpen(false);
            setErasedProduct(null);
          })
          .catch((error) => {
            notify(error?.message, 'error');
            setLoading(false);
            setOpen(false);
          });
      }
    }
  };
  useEffect(() => {
    if (search.length < 6) {
      setLoadProduct(null);
      return;
    }
    setLoading(true);
    const filterValues = { eans: search?.split(','), active: null };
    const variables = { pagination: { page: 1, limit: 200 }, filter: filterValues, search: null };
    const query = gql`${PRODUCTS}`;
    client.query({ query, variables }).then(
      (result) => {
        const { data: json } = result;
        if (result?.errors) {
          setLoadProduct(null);
          setLoading(false);
        } else {
          const { products: res } = json;
          const { results } = res;
          if (results.length > 0) {
            setLoadProduct(results[0]);
          }
          setLoading(false);
        }
      },
    ).catch(() => {
      setLoadProduct(null);
      setLoading(false);
    });
  }, [search]);
  useEffect(() => {
    setLoading(true);
    dataProvider.getList('productsAssociation', {
      pagination: {
        page: 1,
        perPage: 200,
      },
      filter: {
        eans: record.id,
      },
    }).then((data) => {
      setAssociations(data);
      setLoading(false);
    });
    /* eslint-disable-next-line */
  }, [record]);
  useEffect(() => {
    setLoading(true);
    if (associations) {
      const filterValues = {
        eans: associations?.data.map(assoc => assoc.associatedEan),
        active: null,
      };
      const variables = { pagination: { page: 1, limit: 200 }, filter: filterValues, search: null };
      const query = gql`${PRODUCTS}`;
      client.query({ query, variables }).then(
        (result) => {
          const { data: json } = result;
          if (result?.errors) {
            setSelectedProducts([]);
            setLoading(false);
          } else {
            const { products: res } = json;
            const { results } = res;
            setSelectedProducts(results);
            setLoading(false);
          }
        },
      ).catch(() => {
        setProducts([]);
        setLoading(false);
      });
    }
  }, [associations]);
  useEffect(() => {
    products.map(i => addItemIfNotExists(i));
    /* eslint-disable-next-line */
  }, [products]);

  return (
    <>
      <SimpleForm save={null} toolbar={null}>
        <Box style={{
          display: 'flex', flexFlow: 'row', width: '100%', alignItems: 'center',
        }}
        >
          <Box>
            <Typography variant="h2">Associated EANs</Typography>
            <TextInput onChange={e => setSearch(e.target.value)} source="searchField" label="EAN" />
            {loading && (
              <><CircularProgress size={34} /></>
            )}
          </Box>
          {!loadProduct && search && !loading && (
            <Typography variant="h5">No product was found with {search}</Typography>
          )}
          {loadProduct && (
            <>
              <Typography key={loadProduct.id + loadProduct.ean} style={{ display: 'flex', flexFlow: 'row' }}>
                <div style={{ marginRight: '15px' }}>({loadProduct.ean}) {loadProduct.name}</div>
                <Button
                  variant="contained"
                  color="primary"
                  label="Confirm association"
                  style={{ maxWidth: '180px' }}
                  onClick={() => save(loadProduct)}
                />
              </Typography>
            </>
          )}
        </Box>
        <Confirm
          isOpen={open}
          title=""
          content={translate('products.delete.confirmAssoc')}
          onConfirm={deleteEan}
          onClose={() => setOpen(false)}
        >
          <Typography variant="h2" component="h2">
            {translate('products.delete.confirmTitleAssoc')}
          </Typography>
        </Confirm>
      </SimpleForm>
      {selectedProducts.length > 0 && (
        <Box style={{ padding: '15px', marginTop: '-25px' }}>
          {selectedProducts.map(product => (
            <>
              <hr />
              <Typography key={product.id} style={{ display: 'flex', flexFlow: 'row' }}>
                <div style={{ marginRight: '15px' }}>({product.ean}) {product.name}</div>
                <Button
                  variant="contained"
                  color="red"
                  label="Delete association"
                  style={{ maxWidth: '180px', backgroundColor: 'red' }}
                  onClick={() => deleteEanActions(product)}
                  startIcon={<IconSave />}
                />
              </Typography>
            </>
          ))}
        </Box>
      )}
    </>
  );
};

export default ProductAssociation;
