import React, { useState, Fragment, useEffect } from 'react';
import {
  Datagrid,
  TextInput,
  useTranslate,
  SimpleForm,
  Button,
  ListButton,
  CreateButton,
  Loading,
  Pagination, BooleanField, SelectInput,
} from 'react-admin';

import { Box, Card } from '@material-ui/core';
import Dns from '@material-ui/icons/Dns';
import Publish from '@material-ui/icons/Publish';
import CloudUpload from '@material-ui/icons/CloudUpload';
import CardContent from '@material-ui/core/CardContent';
import keyBy from 'lodash/keyBy';
import gql from 'graphql-tag';
import { LinkTextField } from '../../lib/components';
import ProductExporter from './ProductExporter';
import {
  FILTER_SEPARATOR, isValidEan,
  MAX_EAN_FILTER_LENGTH,
  VALID_EAN_LENGTH_13,
  VALID_EAN_LENGTH_6,
} from '../../lib/utils/products';
import PRODUCTS from '../../providers/queries/products';
import client from '../../providers/client';


const ProductsList = ({ permissions }) => {
  const initialPage = 1;
  const initialRefresh = 0;
  const initialSort = 10;
  const initialTotal = 0;
  const minLength = 3;
  const [page, setPage] = useState(initialPage);
  const [perPage, setPerPage] = useState(initialSort);
  const [sort, setSort] = useState({ field: 'id', order: 'ASC' });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(initialTotal);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(initialRefresh);
  const [error, setError] = useState(false);
  const [errorFilter, setErrorFilter] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [filterActive, setFilterActive] = useState(null);
  const [filterMulti, setFilterMulti] = useState('');
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState(null);
  const [toolbarWidth, setToolbarWidth] = useState('355px');
  const translate = useTranslate();
  const formValues = { name: filterName, multiEan: filterMulti, active: filterActive };

  useEffect(() => {
    setLoading(true);
    if (page < initialPage) {
      setPage(initialPage);
    } else {
      const filterValues = { ...filter, active: filterActive };
      const variables = { pagination: { page, limit: perPage }, filter: filterValues, search };
      const query = gql`${PRODUCTS}`;
      client.query({ query, variables }).then(
        (result) => {
          const { data: json } = result;
          if (result?.errors) {
            setData([]);
            setLoading(false);
            setTotal(initialTotal);
            setError(result?.errors.pop().message);
          } else {
            const { products } = json;
            const { results, pagination } = products;
            setData(results);
            setError(false);
            setTotal(pagination?.count);
            setLoading(false);
          }
        },
      ).catch((err) => {
        setData([]);
        setLoading(false);
        setError(false);
        setTotal(initialTotal);
        setError(err.message);
      });
    }
    /* eslint-disable-next-line */
  }, [page, refresh]);
  useEffect(() => {
    const eanList = filterMulti.split(FILTER_SEPARATOR);
    const isInLimit = eanList.length <= MAX_EAN_FILTER_LENGTH;
    const isSingleMode = !filterMulti.includes(FILTER_SEPARATOR);
    let isValidFilter = true;
    if (isSingleMode) {
      isValidFilter = filterMulti.length >= VALID_EAN_LENGTH_6
        && filterMulti.length <= VALID_EAN_LENGTH_13;
    }
    if (isValidFilter && isInLimit) {
      setErrorFilter(false);
      const filterList = eanList
        .map(itemRaw => itemRaw.trim())
        .filter(item => isValidEan(item));
      const filterParsed = {
        eans: filterList,
      };
      setFilter({ ...filterParsed });
      if (page !== initialPage) {
        setPage(initialPage);
      } else {
        setRefresh(Date.now());
      }
    } else {
      if (!isValidFilter && filterMulti !== '') {
        setErrorFilter(translate('products.filter.errorEanLenght'));
      } else
      if (!isInLimit && isValidFilter) {
        setErrorFilter(translate('products.filter.errorEanMax'));
      } else {
        setErrorFilter(false);
      }

      if (filter) {
        setFilter(null);
        if (page !== initialPage) {
          setPage(initialPage);
        } else {
          setRefresh(Date.now());
        }
      }
    }
    /* eslint-disable-next-line */
  }, [filterMulti]);
  useEffect(() => {
    if (filterName.length > minLength) {
      setSearch(filterName);
      if (page !== initialPage) {
        setPage(initialPage);
      } else {
        setRefresh(Date.now());
      }
    } else if (search) {
      setSearch(null);
      if (page !== initialPage) {
        setPage(initialPage);
      } else {
        setRefresh(Date.now());
      }
    }
    /* eslint-disable-next-line */
  }, [filterName]);
  useEffect(() => {
    if (filter?.eans?.length > initialTotal || search?.length > initialTotal) {
      setToolbarWidth('638px');
    } else {
      setToolbarWidth('460px');
    }
  }, [filter, search]);
  useEffect(() => {
    setRefresh(Date.now());
  }, [filterActive]);

  return (
    <Fragment>
      <SimpleForm toolbar={false} submitOnEnter={false} record={formValues}>
        <Box display="flex" justifyContent="space-between" style={{ height: '40px' }}>
          <Box flex={1} style={{ marginRight: '10px', minWidth: '180px', alignSelf: 'center' }}>
            <TextInput source="multiEan" label="Eans" alwaysOn onChange={((event) => { setFilterMulti(event.target.value); })} />
          </Box>
          <Box flex={1} style={{ marginRight: '10px', minWidth: '180px', alignSelf: 'center' }}>
            <TextInput source="name" label="Name" alwaysOn onChange={((event) => { setFilterName(event.target.value); })} />
          </Box>
          <Box flex={2} style={{ marginRight: '10px', minWidth: '180px', alignSelf: 'center' }}>
            <SelectInput
              label={translate('comment.edit.status')}
              source="active"
              choices={[
                { id: true, name: 'ACTIVE' },
                { id: false, name: 'INACTIVE' },
              ]}
              options={{ style: { minWidth: '125px' } }}
              onChange={((event) => { setFilterActive(event?.target.value); })}
            />
          </Box>
          <Box flex={1} style={{ marginRight: '20px', minWidth: '150px', alignSelf: 'center' }}>
            <Button
              label="Reset filter"
              onClick={() => {
                setFilterMulti('');
                setFilterName('');
                setFilterActive(null);
                if (page !== initialPage) {
                  setPage(initialPage);
                } else {
                  setRefresh(Date.now);
                }
              }}
            />
          </Box>
          <Box
            flex={1}
            style={{
              minWidth: toolbarWidth, alignSelf: 'center',
            }}
          >
            {(filter?.eans?.length > initialTotal || search?.length > initialTotal) && (
              <ProductExporter filter={filter} search={search} />
            )}

          </Box>
        </Box>
      </SimpleForm>
      {errorFilter
      && (
        <div
          data-testid="product-list-errorFilter"
          style={{
            color: 'red', marginLeft: '15px', height: '20px', marginTop: '-20px',
          }}
        >
          {errorFilter}
        </div>
      )}
      {error
      && (
        <div style={{
          color: 'red', marginLeft: '15px', height: '20px', marginTop: '-10px', marginBottom: '20px',
        }}
        >
          Error: {error}
        </div>
      )}
      <Card>
        {loading && <Loading />}
        {!loading && (
          <CardContent>
            <div style={{ margin: '10px 0' }}>
              <ListButton
                style={{ marginRight: '5px' }}
                basePath="/products/syndication"
                label="Syndication"
                icon={<CloudUpload />}
              />
              <ListButton
                style={{ marginRight: '5px' }}
                basePath="/products/jobsList"
                label="Jobs List"
                icon={<Dns />}
              />
              <ListButton
                style={{ marginRight: '5px' }}
                basePath="/products/uploadCsv"
                label={translate('products.uploads.buttonLabel')}
                icon={<Publish />}
              />
              <ListButton
                style={{ marginRight: '5px' }}
                basePath="/products/media"
                label="Upload products images"
                icon={<Publish />}
              />
              <ListButton
                style={{ marginRight: '5px' }}
                basePath="/shuffle"
                label={translate('products.shuffle.title')}
                icon={<Publish />}
              />
              <ListButton
                style={{ marginRight: '5px' }}
                basePath="/products/contracts"
                label={translate('products.contracts.title')}
                icon={<Publish />}
              />
              <CreateButton label="Create" />
            </div>
            <Datagrid
              data={keyBy(data, 'ean')}
              ids={data.map(({ ean }) => ean)}
              currentSort={sort}
              setSort={(field, order) => setSort({ field, order })}
            >
              <LinkTextField path="/products" defaultKey="ean" permissions={permissions} source="ean" label="EAN" />
              <LinkTextField path="/products" defaultKey="ean" permissions={permissions} source="name" label="Name" />
              <BooleanField source="active" label={translate('user.list.active')} />
            </Datagrid>
            <Pagination
              page={page}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              total={total}
            />
          </CardContent>
        )}
      </Card>
    </Fragment>
  );
};

export default ProductsList;
