import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Typography } from '@material-ui/core';

const UpdateProductImage = (props) => {
  const {
    ean, file, setOk, setFail, fail, okList,
  } = props;
  const [step, setStep] = useState(null);
  const [product, setProduct] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const steps = {
    initial: 'initial',
    checkingEan: 'checking Ean',
    errorEan: 'Invalid Ean',
    readyUpload: 'Ready to upload',
    uploadingFile: 'Uploading file',
    errorUploadFile: 'Error Upload File',
    readyUpdateProduct: 'Ready to update product',
    updatingProduct: 'Updating Product',
    errorUpdateProduct: 'Error Update Product',
    success: 'Success',
  };
  const errors = [steps.errorUpdateProduct, steps.errorEan, steps.errorUploadFile];
  const dataProvider = useDataProvider();
  const checkEan = async () => {
    setStep(steps.checkingEan);
    try {
      const check = await dataProvider.getOne('products', {
        id: ean,
      });
      const { data } = check;
      const { id } = data;
      if (!id) {
        return setStep(steps.errorEan);
      }
      setProduct(data);
      return setStep(steps.readyUpload);
    } catch (error) {
      if (error) {
        setStep(steps.errorEan);
      }
    }
    return true;
  };
  const UploadFile = async () => {
    setStep(steps.uploadingFile);
    try {
      const uploadCheck = await dataProvider
        .update('productImage', { id: product.uuid, data: { file } });
      const newUrl = uploadCheck?.data?.data?.uploadProductImage?.value;
      if (!newUrl) {
        return setStep(steps.errorUploadFile);
      }
      setProductImage(newUrl);
      return setStep(steps.readyUpdateProduct);
    } catch (error) {
      if (error) {
        setStep(steps.errorUploadFile);
      }
    }
    return true;
  };
  const UpdateProduct = async () => {
    setStep(steps.updatingProduct);
    try {
      const checkUpload = await dataProvider
        .update('products', { data: { ...product, image: productImage } });
      const { data } = checkUpload;
      const { id } = data;
      if (!id) {
        return setStep(steps.errorUpdateProduct);
      }
      return setStep(steps.success);
    } catch (error) {
      if (error) {
        setStep(steps.errorUpdateProduct);
      }
    }
    return true;
  };
  const actionOnStep = (current) => {
    switch (current) {
      case null:
        return setStep(steps.initial);
      case steps.initial:
        return checkEan();
      case steps.readyUpload:
        return UploadFile();
      case steps.readyUpdateProduct:
        return UpdateProduct();
      default:
        return false;
    }
  };
  useEffect(() => {
    actionOnStep(step);
    if (step === steps.success) {
      if (okList.length > 0) {
        setOk([...okList, ean]);
      } else {
        setOk([step]);
      }
    }
    if (errors.includes(step)) {
      if (fail.length > 0) {
        setFail([...fail, step]);
      } else {
        setFail([step]);
      }
    }
    /* eslint-disable-next-line */
  }, [step]);

  const isError = current => errors.includes(current);
  const isSuccess = current => current === steps.success;
  return (
    <div style={isSuccess(step) ? { padding: '0 8px', margin: '3px', border: '1px solid green' } : { padding: '0 8px' }}>
      <img
        key={ean}
        src={URL.createObjectURL(file)}
        alt={`Resized ${ean}`}
        style={{ width: 80, height: 80 }}
      />
      <Typography style={isError(step) ? { color: 'red' } : {}}>{ean}</Typography>
      <Typography style={isError(step) ? { color: 'red' } : {}}>{step}</Typography>
    </div>
  );
};
export default UpdateProductImage;
