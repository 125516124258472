import React, { useState } from 'react';
import {
  Create,
  Loading,
  SaveButton,
  SimpleForm,
  Toolbar,
  useCreateController,
  useDataProvider,
  useNotify, useRedirect,
} from 'react-admin';

import StaticForm from './StaticForm';
import { RADIX_TEN } from '../promotions/constants';
import { validateStaticContentForm } from './rules';


const StaticCreate = ({ permissions, ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const { loading, saving } = useCreateController(props);
  const [contentDesktop, setContentDesktop] = useState('');
  const [contentMobile, setContentMobile] = useState('');
  const [splitContents, setSplitContents] = useState(false);

  const StaticToolBar = staticProps => (
    <Toolbar {...staticProps}>
      <SaveButton disabled={loading || saving} />
    </Toolbar>
  );

  const handleSave = (values) => {
    const blobDesktop = new Blob([contentDesktop], { encoding: 'UTF-8', type: 'text/plain;charset=UTF-8' });
    const blobMobile = splitContents
      ? new Blob([contentMobile], { encoding: 'UTF-8', type: 'text/plain;charset=UTF-8' })
      : blobDesktop;

    dataProvider.create('staticContent', {
      input: {
        alias: values.alias,
        desktopContent: blobDesktop,
        mobileContent: blobMobile,
        name: values.name,
        position: values?.position === null ? null : parseInt(values.position, RADIX_TEN),
        status: values.status,
      },
    })
      .then((res) => {
        notify('Content created successfully', 'success');
        const { data } = res;
        const { id } = data;

        redirect(`/staticContent/${id}`);
      })
      .catch(err => notify(err?.message, 'error'));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Create {...props} redirect="edit">
      <SimpleForm save={handleSave} toolbar={<StaticToolBar />} validate={validateStaticContentForm}>
        <StaticForm
          splitContents={splitContents}
          setSplitContents={setSplitContents}
          contentDesktop={contentDesktop}
          setContentDesktop={setContentDesktop}
          contentMobile={contentMobile}
          setContentMobile={setContentMobile}
          {...props}
        />
      </SimpleForm>
    </Create>
  );
};

export default StaticCreate;
