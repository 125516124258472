import React, { useState, useEffect } from 'react';

import { Labeled, useDataProvider } from 'react-admin';


const AssociationEanField = (props) => {
  const { record } = props;
  const dataProvider = useDataProvider();
  const [associations, setAssociations] = useState([]);
  const [text, setText] = useState(' ');
  const labelText = 'Associated EANs';
  useEffect(() => {
    dataProvider.getList('productsAssociation', {
      pagination: {
        page: 1,
        perPage: 200,
      },
      filter: {
        associatedEans: [record.ean],
      },
    }).then((res) => {
      const { data, total } = res;
      if (total > 0) {
        setAssociations(data);
      }
    });
    /* eslint-disable-next-line */
  }, [record]);
  useEffect(() => {
    if (associations.length > 0) {
      const textActual = associations?.map(a => a.ean).join(', ');
      return setText(textActual);
    }
    return setText('');
  }, [associations]);
  if (!text) {
    return (<Labeled label={labelText} />);
  }
  return (
    <Labeled label={labelText}>
      <textarea disabled rows={5} style={{ border: '0' }}>{text}</textarea>
    </Labeled>
  );
};

export default AssociationEanField;
