import React, { useLayoutEffect, useState } from 'react';
import gql from 'graphql-tag';
import {
  useTranslate, ListButton, FileField, FileInput, SimpleForm,
} from 'react-admin';
import CardContent from '@material-ui/core/CardContent';
import {
  Box, Card, Step, StepLabel, Stepper, Typography,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

import ShowJobStatus from './ShowJobStatus';
import client from '../../providers/client';
import UPLOAD_PRODUCT_CSV from '../../providers/mutations/uploadProductCsv';
import { CSV_TEMPLATE_URL } from '../../lib/utils';
import ProcessToolbar from './uploadCsv/ProcessToolBar';

const jschardet = require('jschardet');

const UploadCsv = () => {
  const PROGRESS = {
    INITIAL: 0,
    UPLOADING_FILE: 5,
    PROCESSING_FILE: 10,
    END: 100,
  };
  const zero = 0;
  const initialStep = 0;
  const initialErrorStep = -1;
  const translate = useTranslate();
  const [resource] = useState('products');
  const [processing, setProcessing] = useState(false);
  const [checking, setChecking] = useState(false);
  const [encodeError, setEncodeError] = useState(false);
  const [encodeErrorType, setEncodeErrorType] = useState('');
  const [status, setStatus] = useState('');
  const [currentJob, setCurrentJob] = useState('');
  const [activeStep, setActiveStep] = useState(initialStep);
  const [errorStep, setErrorStep] = useState(initialErrorStep);
  const [progress, setProgress] = useState(PROGRESS.INITIAL);
  const [showProgress, setShowProgress] = useState(false);
  const globalFont = { fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif' };
  const STATUS = {
    UPLOADING_FILE: translate('products.uploads.processing.status.upload'),
    PROCESSING_FILE: translate('products.uploads.processing.status.process'),
    END: translate('products.uploads.processing.status.end'),
  };
  const steps = [STATUS.UPLOADING_FILE, STATUS.PROCESSING_FILE, STATUS.END];
  const isStepFailed = step => step === errorStep;
  const setEndWithError = (msg, stepWithError) => {
    setProgress(PROGRESS.END);
    setCurrentJob(null);
    setStatus(msg);
    setErrorStep(steps.indexOf(stepWithError));
    setProcessing(false);
  };
  const setEnd = () => {
    setProgress(PROGRESS.END);
    setStatus(STATUS.END);
    setActiveStep(steps.indexOf(STATUS.END));
    setProcessing(false);
  };
  const handleSave = (values) => {
    setStatus('');
    setProgress(PROGRESS.INITIAL);
    setActiveStep(initialStep);
    setErrorStep(initialErrorStep);
    setProcessing(false);

    const { productsCsv } = values || null;
    const { rawFile } = productsCsv || null;
    if (rawFile) {
      setProcessing(true);
      setShowProgress(true);
      setProgress(PROGRESS.INITIAL);
      setProgress(PROGRESS.UPLOADING_FILE);
      setStatus(STATUS.UPLOADING_FILE);
      setActiveStep(steps.indexOf(STATUS.UPLOADING_FILE));
      const mutation = gql`${UPLOAD_PRODUCT_CSV}`;
      client.mutate({ mutation, variables: { file: rawFile } })
        .then((res) => {
          const { data } = res;
          const { uploadProductsFromCsv } = data;

          if (uploadProductsFromCsv === null) {
            const { errors } = res;
            const errorMsg = errors[zero].message;
            return setEndWithError(errorMsg, STATUS.UPLOADING_FILE);
          }

          const { bulkJobId } = uploadProductsFromCsv;
          setCurrentJob(bulkJobId);
          setStatus(STATUS.PROCESSING_FILE);
          setActiveStep(steps.indexOf(STATUS.PROCESSING_FILE));
          setProgress(PROGRESS.PROCESSING_FILE);

          return true;
        })
        .catch((err) => {
          setEndWithError(err.message, STATUS.UPLOADING_FILE);
        });
    }
  };

  const handleChange = (event) => {
    setEncodeError(false);
    setEncodeErrorType('');

    if (event !== null) {
      setChecking(true);
      const reader = new FileReader();
      reader.readAsDataURL(event);
      reader.onload = () => {
        const { result } = reader;
        const search = result.search('data:text/csv;base64,');

        if (search === zero) {
          const parsed = result.replace('data:text/csv;base64,', '');
          const fromBase64 = atob(parsed);
          const encodedIn = jschardet.detect(fromBase64, { minimumThreshold: 0 });
          const { encoding } = encodedIn;

          if (encoding === 'UTF-8' || encoding === 'ascii') {
            setChecking(false);
          } else {
            setEncodeError(true);
            setEncodeErrorType(encoding);
          }
        }
      };
    }
  };

  useLayoutEffect(() => {
    if (currentJob && currentJob !== '' && currentJob !== 'null') {
      setCurrentJob(currentJob);
      setProcessing(true);
      setShowProgress(true);
      setStatus(STATUS.PROCESSING_FILE);
      setActiveStep(steps.indexOf(STATUS.PROCESSING_FILE));
      setProgress(PROGRESS.PROCESSING_FILE);
    }
    /* eslint-disable-next-line */
  }, []);

  return (
    <Card style={{ width: '100%' }}>
      <CardContent>
        <ListButton basePath={`/${resource}`} />
        <h3 style={{ ...globalFont }}>
          {translate('products.uploads.title')}
        </h3>
        <FileField
          record={{ url: CSV_TEMPLATE_URL() }}
          source="url"
          title={translate('products.uploads.file.title')}
          style={{ ...globalFont }}
        />
        <Box />
        <SimpleForm
          save={handleSave}
          saving={processing}
          toolbar={<ProcessToolbar checking={checking} />}
        >
          <FileInput
            source="productsCsv"
            label={translate('products.uploads.form.label')}
            accept=".csv"
            placeholder={<p>{translate('products.uploads.form.placeholder')}</p>}
            onChange={handleChange}
          >
            <FileField source="src" title={translate('products.uploads.form.preview')} />
          </FileInput>
          {encodeError
            && (
            <Typography style={{ width: '100%' }}>
              {translate(
                'products.uploads.file.encodeError',
                { actual: `${encodeErrorType}` },
              )}
            </Typography>
            )
          }
        </SimpleForm>
        <Box />
        { showProgress ? (
          <>
            <h3 style={{ ...globalFont }}>
              {translate('products.uploads.processing.title')}
            </h3>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const labelProps = {};
                if (isStepFailed(index)) {
                  labelProps.error = true;
                }
                return (
                  <Step key={label}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <LinearProgress variant="determinate" size={25} thickness={2} value={progress} />
            <Box
              style={{ ...globalFont, marginTop: '10px' }}
              component="div"
            >
              {translate('products.uploads.processing.span')}: {status}
            </Box>
            {status !== STATUS.UPLOADING_FILE
              ? (
                <ShowJobStatus
                  jobId={currentJob}
                  setEndWithError={setEndWithError}
                  setEnd={setEnd}
                  setProgress={setProgress}
                  STATUS={STATUS}
                />
              )
              : null}
          </>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default UploadCsv;
