import ProductIcon from '@material-ui/icons/ShoppingCart';
import ProductList from './ProductList';
import ProductCreate from './ProductCreate';
import ProductTab from './ProductTab';

export default {
  list: ProductList,
  icon: ProductIcon,
  create: ProductCreate,
  edit: ProductTab,
};
