import React, { useState, useEffect } from 'react';
import {
  FormDataConsumer,
  DateInput,
  TextInput,
  useTranslate,
  SelectInput,
  CheckboxGroupInput,
  ArrayInput,
  SimpleFormIterator,
  required,
} from 'react-admin';
import { useFormState, useForm } from 'react-final-form';

import { Box, Toolbar, Typography } from '@material-ui/core';
import CreateBtn from './buttons/CreateBtn';
import EditBtn from './buttons/EditBtn';
import {
  allergyOptions,
  binaryOptions,
  childComponentOptions, dietsOptions,
  genderOptions,
  petsOptions,
} from '../../lib/utils/profile';


const ProfileFormUi = (props) => {
  const { setSaveLoading, setProfile } = props;
  const translate = useTranslate();
  const { values } = useFormState();
  const { numberOfChildren } = values;
  const { change: changeFieldValue } = useForm();
  const [errorChild] = useState(false);
  const zero = 0;

  useEffect(() => {
    const childrenCount = parseInt(values?.numberOfChildren, 10) || zero;
    const childrenListCount = values?.children?.length || zero;
    if (childrenCount === zero) {
      changeFieldValue('children', []);
    } else if (childrenCount !== childrenListCount) {
      const concordantValue = values?.children?.slice(zero, childrenCount);

      if (childrenCount > childrenListCount) {
        const diff = childrenCount - childrenListCount;
        const sum = 1;

        for (let i = zero; i < diff; i += sum) {
          concordantValue.push(undefined);
        }
      }
      changeFieldValue('children', concordantValue);
    }
    /* eslint-disable-next-line */
  }, [numberOfChildren]);

  const validateChildInput = (value) => {
    if (!value) {
      return 'Enter a date of birth';
    }

    const regexToTest = new RegExp('(0[1-9]|[12][0-9]|3[01])\\/(0[1-9]|1[1,2])\\/\\d{4}$');

    if (value && !regexToTest.test(value) && !value.includes('*')) {
      return 'Enter a valid date format: DD/MM/YYYY';
    }

    return null;
  };

  const handleOnFocus = (prop) => {
    if (prop?.target) {
      const identifier = prop.target.getAttribute('name');
      changeFieldValue(identifier, null);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h2" component="h2">
        {translate('user.edit.title1')}
      </Typography>
      <SelectInput
        fullWidth
        source="gender"
        choices={genderOptions(translate)}
        label={translate('user.edit.gender.title')}
      />
      <TextInput
        validate={validateChildInput}
        fullWidth
        source="birthDate"
        onClick={() => { changeFieldValue('birthDate', ''); }}
        label={translate('user.edit.birth')}
      />
      <Typography variant="h2" component="h2">
        {translate('user.edit.title2')}
      </Typography>
      <Typography style={{ marginTop: '15px' }} variant="h3" component="h3">
        {translate('user.edit.title21')}
      </Typography>
      <SelectInput
        fullWidth
        source="numberOfChildren"
        choices={childComponentOptions}
        label={translate('user.edit.numberOfChildren')}
      />
      <FormDataConsumer>
        {({ formData }) => (formData.numberOfChildren !== '0' && formData.numberOfChildren !== 'NOT_ANSWERED' && (
          <ArrayInput source="children" label="">
            <SimpleFormIterator
              TransitionProps={{ enter: false, exit: false }}
              disableAdd
              disableRemove
              disableReordering
            >
              <TextInput
                validate={validateChildInput}
                source="birthDate"
                label={translate('user.edit.birthDate')}
                onFocus={handleOnFocus}
              />
            </SimpleFormIterator>
          </ArrayInput>
        ))}
      </FormDataConsumer>
      <SelectInput
        fullWidth
        source="hasPet"
        choices={binaryOptions(translate)}
        label={translate('user.edit.has_pet')}
      />
      <FormDataConsumer>
        {({ formData }) => (formData.hasPet === 'YES' && (
          <CheckboxGroupInput
            source="petsValues"
            validate={[required()]}
            choices={petsOptions(translate)}
            label={translate('user.edit.pets')}
            fullWidth
          />
        ))}
      </FormDataConsumer>
      <Typography variant="h3" component="h3">
        {translate('user.edit.title22')}
      </Typography>
      <SelectInput
        fullWidth
        format={v => (v !== null ? v : translate('user.edit.not_answered'))}
        parse={v => (v !== translate('user.edit.not_answered') ? v : null)}
        source="diet"
        choices={dietsOptions(translate)}
        label={translate('user.edit.diet')}
      />
      <SelectInput
        fullWidth
        source="hasAllergy"
        choices={binaryOptions(translate)}
        label={translate('user.edit.has_allergy')}
      />
      <FormDataConsumer>
        {({ formData }) => (formData.hasAllergy === 'YES' && (
          <CheckboxGroupInput
            source="allergyValues"
            validate={[required()]}
            choices={allergyOptions(translate)}
            label={translate('user.edit.allergies')}
            fullWidth
          />
        ))}
      </FormDataConsumer>
      <SelectInput
        fullWidth
        format={v => (v !== null ? v : translate('user.edit.not_answered'))}
        parse={v => (v !== translate('user.edit.not_answered') ? v : null)}
        source="hasAlcohol"
        choices={binaryOptions(translate)}
        label={translate('user.edit.has_alcohol')}
      />
      <DateInput
        source="updatedAt"
        disabled
        label={translate('user.edit.updatedAt')}
        fullWidth
      />
      <Toolbar>
        <Box display="flex">
          <FormDataConsumer>
            {({ formData, ...rest }) => (formData.createdMode === true
              ? (
                <CreateBtn
                  form={formData}
                  customLoad={setSaveLoading}
                  customProfile={setProfile}
                  errorHomeCom={errorChild}
                  {...rest}
                />
              )
              : (
                <EditBtn
                  form={formData}
                  customLoad={setSaveLoading}
                  customProfile={setProfile}
                  errorHomeCom={errorChild}
                  {...rest}
                />
              )
            )}
          </FormDataConsumer>
        </Box>
      </Toolbar>
    </div>
  );
};

export default ProfileFormUi;
