import keyBy from 'lodash/keyBy';
import React, { useState, Fragment, useEffect } from 'react';
import {
  Datagrid,
  TextField,
  Pagination,
  Loading, ListButton, FunctionField, useNotify, useRedirect, useTranslate,
} from 'react-admin';
import gql from 'graphql-tag';
import moment from 'moment';
import { Box, Card, CircularProgress } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LaunchIcon from '@material-ui/icons/Launch';
import client from '../../providers/client';
import BULK_JOBS_LIST from '../../providers/queries/bulkJobsList';
import DELETE_BULK_JOB from '../../providers/mutations/deleteBulkJob';
import JobStatusLegend from './JobStatusLegend';

const BulkJobs = () => {
  const initialPage = 1;
  const initialRefresh = 0;
  const initialSort = 10;
  const initialTotal = 0;
  const [resource] = useState('products');
  const [page, setPage] = useState(initialPage);
  const [perPage, setPerPage] = useState(initialSort);
  const [sort, setSort] = useState({ field: 'key', order: 'ASC' });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(initialTotal);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [refresh, setRefresh] = useState(initialRefresh);
  const [error, setError] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();

  const parseResultsData = (resultData) => {
    const sum = 1;
    const output = [];
    let key = initialTotal;
    resultData.forEach((item) => {
      key += sum;
      const parsedItem = {
        id: item.id,
        key,
        status: item.status,
        startedAt: item.startedAt !== null ? moment(item.startedAt).format('L H:mm:ss') : null,
        finishedAt: item.finishedAt !== null ? moment(item.finishedAt).format('L H:mm:ss') : null,
      };

      output.push(parsedItem);
    });

    setData(output);
  };

  const deleteJob = (bulkJobId) => {
    const mutation = gql`${DELETE_BULK_JOB}`;
    setDeleting(true);
    client.mutate({ mutation, variables: { id: bulkJobId } })
      .then((res) => {
        const { data: bulkData } = res;
        const { deleteBulkJob } = bulkData;
        setDeleting(false);
        if (deleteBulkJob === true) {
          notify(translate('products.jobsList.deleting.success'), 'success');
          setRefresh(Date.now());
        } else {
          setDeleting(false);
          notify(translate('products.jobsList.deleting.error'), 'warning');
        }
        return true;
      })
      .catch(() => {
        setDeleting(false);
        notify(translate('products.jobsList.deleting.error'), 'warning');
      });
  };

  const renderActionField = (params) => {
    if (params.status === 'FINISHED') {
      return <LaunchIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => redirect(`/products/job/${params.id}`)} />;
    }

    return deleting
      ? <CircularProgress size={20} thickness={1} />
      : (
        <>
          <LaunchIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => redirect(`/products/job/${params.id}`)} />
          <DeleteForeverIcon style={{ cursor: 'pointer', color: '#d32f2f', marginLeft: '5px' }} onClick={() => deleteJob(params.id)} />
        </>
      );
  };

  useEffect(() => {
    setLoading(true);
    if (page < initialPage) {
      setPage(initialPage);
    } else {
      const variables = { pagination: { page, limit: perPage } };
      const query = gql`${BULK_JOBS_LIST}`;
      client.query({ query, variables }).then(
        (result) => {
          const { data: json } = result;
          const { bulkJobs } = json;
          const { results, pagination } = bulkJobs;

          parseResultsData(results);
          setError(false);
          setTotal(pagination?.count);
          setLoading(false);
        },
      ).catch(err => setError(err.message));
    }
    /* eslint-disable-next-line */
  }, [page, refresh]);

  useEffect(() => {
    if (page !== initialPage) {
      setPage(initialPage);
    } else {
      setRefresh(Date.now());
    }
    /* eslint-disable-next-line */
  }, [perPage]);

  if (error) {
    return <p>ERROR: {error}</p>;
  }
  return (
    <Fragment>
      <Box display="flex" justifyContent="space-between" style={{ height: '40px' }}>
        <Box flex={1} style={{ marginRight: '10px', minWidth: '120px', alignSelf: 'center' }}>
          <ListButton basePath={`/${resource}`} />
        </Box>
      </Box>
      <Card>
        {loading && <Loading />}
        {!loading && (
          <CardContent>
            <JobStatusLegend />
            <Box style={{ margin: '10px 0 20px' }} />
            <Datagrid
              data={keyBy(data, 'id')}
              ids={data.map(({ id }) => id)}
              currentSort={sort}
              setSort={(field, order) => setSort({ field, order })}
            >
              <TextField source="key" label="#" />
              <TextField source="status" />
              <TextField source="startedAt" />
              <TextField source="finishedAt" />
              <FunctionField
                source="action"
                render={record => renderActionField(record)}
              />
            </Datagrid>
            <Pagination
              page={page}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              total={total}
            />
          </CardContent>
        )}
      </Card>
    </Fragment>
  );
};

const JobsList = () => (
  <Fragment>
    <BulkJobs />
  </Fragment>
);

export default JobsList;
