import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useDataProvider, useNotify } from 'react-admin';
import HistoricSearchUi from '../../lib/components/HistoricSearchUi';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'baseline',
    gap: '0 20px',
    margin: '20px 0 10px 0',
  },
  resultContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    margin: '30px 0 10px 0',
  },
  button: {
    height: '40px',
  },
  italic: {
    fontStyle: 'italic',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  pagination: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0 30px',
  },
}));

const HistoricSearch = () => {
  const initialPage = 1;
  const perPage = 10;
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [data, setData] = useState(undefined);
  const [filter, setFilter] = useState(undefined);
  const [search, setSearch] = useState(undefined);
  const [page, setPage] = useState(undefined);
  const [total, setTotal] = useState(undefined);
  const [canPrev, setCanPrev] = useState(false);
  const [canNext, setCanNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const t = useTranslate();
  const notify = useNotify();

  const handleQuery = () => {
    setLoading(true);
    const variables = {
      pagination: {
        perPage,
        page,
      },
      filter: {
        codes: [filter],
      },
    };
    setSearch(filter);
    setData(undefined);

    dataProvider
      .getList('cardsHistory', { ...variables })
      .then((res) => {
        const { data: queryData, pagination, total: totalCount } = res;
        setData(queryData);
        setTotal(totalCount);
        setCanPrev(pagination?.prev !== null);
        setCanNext(pagination?.next !== null);
        setLoading(false);
      })
      .catch((err) => {
        notify(err?.message, 'error');
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    if (filter) {
      setPage(initialPage);
      handleQuery();
    }
  };

  useEffect(() => {
    if (page && filter) {
      handleQuery();
    }
    /* eslint-disable-next-line */
  }, [page]);

  return (
    <>
      <Typography variant="h1">{t('cards.history.title')}</Typography>
      <Box className={classes.container}>
        <TextField label={t('cards.history.searchLabel')} onChange={e => setFilter(e.target.value)} />
        <Button
          className={classes.button}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!filter || filter === ''}
        >
          Search
        </Button>
      </Box>
      {search && (
        <HistoricSearchUi
          isDetail="search"
          data={data}
          code={search}
          loading={loading}
          total={total}
          canPrev={canPrev}
          canNext={canNext}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  );
};

export default HistoricSearch;
