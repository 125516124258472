const UPLOAD_FILE_TO_STORAGE = `
  mutation UploadFileToStorage(
    $file: Upload!,
    $path: String!,
    $bucket: String!
  ) {
    uploadFileToStorage(
      file: $file,
      path: $path,
      bucket: $bucket
    )
  }
`;

export default UPLOAD_FILE_TO_STORAGE;
