import React from 'react';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { TopToolbar } from 'react-admin';
import UploadContracts from '../../../lib/components/uploadContracts';

const ShowActions = () => (
  <TopToolbar>
    <UploadContracts />
  </TopToolbar>
);
const ContractsPage = () => (
  <Card>
    <CardContent>
      <ShowActions />
    </CardContent>
  </Card>
);

export default ContractsPage;
