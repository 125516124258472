import React, { useEffect, useState } from 'react';
import {
  Filter, SelectArrayInput, TextInput, minLength, DateInput, SelectInput, NullableBooleanInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useListContext } from 'ra-core';
import { filterMixedCommentOptions } from '../../lib/utils';
import { RATING } from '../promotions/constants';
import { STATUS_TYPES } from '../../lib/utils/Comments';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  filter: {
    height: '60px',
  },
  filterLong: {
    height: '60px',
    width: '180px',
  },
}));
const CommentFilter = ({ translate, permissions, ...props }) => {
  const minSearch = 3;
  const validateMinLength = [minLength(minSearch)];
  const classes = useStyles();
  const { filterValues } = useListContext(props);
  const [showFilter, setShowFilter] = useState(false);
  useEffect(() => {
    const { ean } = filterValues;
    if (ean) {
      return setShowFilter(true);
    }
    return setShowFilter(false);
  }, [filterValues]);
  return (
    <Filter {...props} data-testid="user-search">
      <TextInput className={classes.filter} validate={validateMinLength} source="ean" alwaysOn label={translate('comment.edit.ean')} />
      <TextInput className={classes.filter} validate={validateMinLength} source="username" alwaysOn label={translate('comment.edit.username')} />
      <TextInput className={classes.filter} validate={validateMinLength} source="search" alwaysOn label={translate('comment.edit.text')} />
      <SelectArrayInput
        className={classes.filter}
        label={translate('comment.edit.filterMixed')}
        source="filterMixed"
        choices={filterMixedCommentOptions}
        options={{ style: { minWidth: '150px' } }}
      />
      <SelectInput
        className={classes.filter}
        label={translate('comment.edit.status')}
        source="status"
        choices={[
          { id: STATUS_TYPES.REJECTED, name: STATUS_TYPES.REJECTED },
          { id: STATUS_TYPES.PUBLISHED, name: STATUS_TYPES.PUBLISHED },
        ]}
        options={{ style: { minWidth: '125px' } }}
        allowEmpty={false}
      />
      <SelectArrayInput
        className={classes.filter}
        multiple
        label={translate('comment.edit.rating')}
        source="filterRating"
        choices={RATING}
        options={{ style: { minWidth: '85px' } }}
      />
      <DateInput source="startDateFrom" label={translate('comment.list.filter.startDate')} />
      <DateInput source="startDateUntil" label={translate('comment.list.filter.endDate')} />
      <NullableBooleanInput
        disabled={showFilter === false}
        className={classes.filterLong}
        label={translate('comment.edit.withEan')}
        source="withAssociatedEan"
      />
    </Filter>
  );
};

export default CommentFilter;
