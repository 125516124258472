import React from 'react';
import { useTranslate, useEditController } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import { TabbedLayout, Tab } from '../../lib/components';
import ProductEdit from './ProductEdit';
import AssociatedEans from '../../lib/components/AssociatedEans';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

let recordData;

const CustomEdit = (props) => {
  const { record } = useEditController(props);
  const translate = useTranslate();
  const classes = useStyles();
  recordData = record;

  return (
    <div className={classes.root}>
      <TabbedLayout>
        <Tab label={translate('products.tabs.info')} className={classes.content}>
          <ProductEdit {...props} />
        </Tab>
        <Tab label={translate('products.tabs.reviews')} className={classes.content} path="associated-eans">
          {record && (<AssociatedEans record={recordData} {...props} />)}
        </Tab>
      </TabbedLayout>
    </div>
  );
};

const ProductTab = ({ permissions, ...props }) => (
  <CustomEdit {...props} undoable={false} />

);
export default ProductTab;
