import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useTranslate } from 'react-admin';
import { Box } from '@material-ui/core';
import client from '../../providers/client';
import BULK_JOB from '../../providers/queries/bulkJob';
import UseInterval from '../../lib/hooks/UseInterval';

const zero = 0;
const complete = 1;
const fullLoad = 90;
const base = 10;
const baseRefresh = 2000;
const isJobDeleted = results => (results?.length === zero);
const isJobFinish = (status, progress) => (status === 'FINISHED' || progress === complete);

const globalFont = { fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif' };
const showEan = ({ ean, status, errors }) => (
  <Box style={{
    width: '100%', ...globalFont, marginTop: '5px',
  }}
  >
    <Box>
      {ean}: {status}
    </Box>
    <Box>
      {errors.map(value => (<p>{value}</p>))}
    </Box>
  </Box>
);

const ShowJobStatus = ({
  jobId, setEndWithError, STATUS, setEnd, setProgress,
}) => {
  const translate = useTranslate();
  const [refreshIdle, setRefreshIdle] = useState(null);
  const [jobProgress, setJobProgress] = useState(zero);
  const [eanProcessed, setEanProcessed] = useState([]);
  const [eanInProgress, setEanInProgress] = useState([]);

  const getData = () => {
    const query = gql`${BULK_JOB}`;
    const ids = [jobId];
    const variables = { filter: { ids } };
    client
      .query({ query, variables })
      .then((res) => {
        const { data } = res;

        if (data === undefined) {
          const { errors } = res;

          if (errors !== undefined) {
            setRefreshIdle(null);
            const errorMsg = errors[zero].message;
            return setEndWithError(errorMsg, STATUS.PROCESSING_FILE);
          }
        }

        const { bulkJobs } = data;
        const { results } = bulkJobs;

        if (isJobDeleted(results)) {
          return setEndWithError(translate('products.uploads.error.deleted'), STATUS.PROCESSING_FILE);
        }

        const jobData = results[zero];
        const { parts, status, progress } = jobData;
        const parsedLog = parts;
        const sumProg = Math.round(parseFloat(progress) * fullLoad);
        const processed = parsedLog.filter(item => item.status === 'FINISHED');
        const inProgress = parsedLog.filter(item => item.status === 'CREATED');
        setEanProcessed(processed);
        setEanInProgress(inProgress);
        setJobProgress(sumProg);

        if (isJobFinish(status, progress)) {
          setRefreshIdle(null);
          return setEnd();
        }

        return true;
      })
      .catch();
  };

  UseInterval(() => {
    getData();
  }, refreshIdle);

  useEffect(() => {
    getData();
    setRefreshIdle(baseRefresh);
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    setProgress(jobProgress + base);
    /* eslint-disable-next-line */
  }, [jobProgress]);
  return (
    <Box style={{ width: '100%', ...globalFont }}>
      <hr />
      <div style={{ ...globalFont }}>
        <b>
          {translate('products.uploads.jobs.title')}{eanProcessed.length + eanInProgress.length}
          {translate('products.uploads.jobs.title_2')}
        </b>
      </div>
      <hr />
      <div><b>{translate('products.uploads.jobs.titleProgress')}</b>: {eanInProgress.length}</div>
      <hr />
      <div><b>{translate('products.uploads.jobs.titleComplete')}</b>: {eanProcessed.length}</div>
      <hr />
      <Box>
        {eanProcessed.map((item) => {
          const { key: ean, status, errors } = item;
          return showEan({ ean, status, errors });
        })}
      </Box>
    </Box>
  );
};

export default ShowJobStatus;
