import React, { useState, useEffect } from 'react';
import { useTranslate, useNotify } from 'react-admin';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { gql, useMutation } from '@apollo/client';

import { USER_BUSINESS_DOMAIN_NAME } from './buildQueryUsers';
import { generateChecksum } from '../../lib/utils';

import userPointsFormStyles from './userPointsFormStyles';

import client from '../../providers/client';

const MIN_POINTS = 0;

const SOURCE = 'point-saz';

const UserAdditionalPointsForm = ({
  action,
  isOpen,
  switchDialog,
  record,
  setReload,
}) => {
  const [concept, setConcept] = useState(null);
  const [points, setPoints] = useState(null);
  const [startDate, setStartDate] = useState(moment().utc().format());
  const [endDate, setEndDate] = useState(null);
  const [formParams, setFormParams] = useState(null);
  const [onCompleted, setOnCompleted] = useState(null);
  const notify = useNotify();
  const translate = useTranslate();
  const classes = userPointsFormStyles();

  const ADD_ADDITIONAL_POINTS = gql`
    mutation AddPointsUserScheduler($input: AddPointsUserSchedulerInput!) {
      AddPointsUserScheduler(input: $input)
    }
  `;

  const [addPointsAction, { loading, data, error }] = useMutation(ADD_ADDITIONAL_POINTS, {
    variables: {
      input: {
        userId: record?.id,
        name: concept,
        amount: points,
        startDateAt: startDate,
        endDateAt: endDate,
      },
    },
    client,
  });

  useEffect(() => {
    if (formParams) {
      addPointsAction();
    }
    /* eslint-disable-next-line */
  }, [formParams]);

  useEffect(() => {
    if (data) {
      setOnCompleted(data);
    }
  }, [data]);

  useEffect(() => {
    if (data && onCompleted) {
      switchDialog(false);
      setConcept(null);
      setPoints(null);
      setOnCompleted(null);
      notify(translate('points.actions.pointsAdded'));
      setReload(true);
    }
    /* eslint-disable-next-line */
  }, [onCompleted]);

  useEffect(() => {
    if (error) {
      notify(`Sorry, there was an error: ${error} `, 'warning');
    }
    /* eslint-disable-next-line */
  }, [error]);

  const handleConfirm = () => {
    const checksumObject = {
      business_domain: USER_BUSINESS_DOMAIN_NAME,
      id: record.id,
      action: `backoffice ${action} points at ${moment().format()}`,
      user_id: record.id,
    };

    setFormParams({
      action,
      checksum: generateChecksum(checksumObject),
      userId: record.id,
      source: SOURCE,
      amount: points,
      concept,
    });
  };

  const handleCancel = () => {
    switchDialog(false);
    setConcept(null);
    setPoints(null);
    setStartDate(moment().utc().format());
    setEndDate(null);
  };

  const checkDisabled = () => concept === null
    || points === null
    || startDate === null
    || endDate === null;

  const addConcept = (e) => {
    setConcept(e.target.value);

    if (e.target.value === '') {
      setConcept(null);
    }
  };

  const addPoints = (e) => {
    setPoints(parseInt(e.target.value, 10));

    if (e.target.value === '') {
      setPoints(null);
    }
  };

  const parseDate = (date, type) => {
    const dateWithHour = `${date} ${type === 'start' ? '00:00:00' : '23:59:59'}`;

    return moment(dateWithHour).isValid ? moment(dateWithHour).utc().format() : null;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => switchDialog(false)}
      aria-labelledby="form-dialog-title"
      data-cy="user-points-form"
    >
      <DialogTitle id="form-dialog-title">{translate('points.actions.addPoints')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="concept"
          label={translate('points.actions.concept')}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => addConcept(e)}
        />
        <TextField
          id="amount"
          label={translate('points.actions.amount')}
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={points === MIN_POINTS ? null : points}
          onChange={e => addPoints(e)}
          InputProps={{ inputProps: { min: MIN_POINTS } }}
        />
        <TextField
          id="startDate"
          label={translate('points.actions.startDate')}
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => setStartDate(parseDate(e.target.value, 'start'))}
          value={startDate && moment(startDate).isValid() ? moment(startDate).local().format('YYYY-MM-DD') : null}
        />
        <TextField
          id="endDate"
          label={translate('points.actions.endDate')}
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => setEndDate(parseDate(e.target.value, 'end'))}
          value={endDate && moment(endDate).isValid() ? moment(endDate).local().format('YYYY-MM-DD') : null}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleCancel()}
          color="primary"
          disabled={loading}
        >
          {translate('points.actions.cancel')}
        </Button>
        <Button
          color="primary"
          onClick={() => handleConfirm()}
          onClose={() => switchDialog(false)}
          disabled={checkDisabled() || loading}
          data-cy="confirm-points-button"
        >
          {loading ? (
            <CircularProgress
              size={15}
              thickness={2}
              className={classes.loading}
            />
          ) : null}
          {translate('points.actions.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserAdditionalPointsForm;
