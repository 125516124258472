import memoize from 'lodash/memoize';
import { useMediaQuery } from '@material-ui/core';

import React, { useState, useEffect } from 'react';
import {
  useTranslate,
  DateField,
  Filter,
  List,
  SimpleList,
  DateInput,
  TextInput,
  useDataProvider,
} from 'react-admin';

import EANListPopover from './EANListPopover';
import { LinkTextField, ListActions, EmptyList } from '../../lib/components';
import PromotionStatusRollover from './PromotionStatusRollover';
import { STATUS } from './constants';
import MultiSelectDropdown from './MultiSelectDropdown';
import CustomDatagrid from './CustomDatagrid';
import promotionExport from './PromotionExporter';
import RetailerFilterDropdown from './RetailerFilterDropdown';


const PromotionFilter = ({
  translate, permissions, pickupPoints, selectedPickupPoints, ...props
}) => (
  <Filter {...props} data-testid="promotion-search">
    <TextInput source="name" alwaysOn label={translate('promotion.filter.name')} />
    <MultiSelectDropdown
      source="statusKey"
      label={translate('promotion.filter.status')}
      choices={STATUS}
    />
    <RetailerFilterDropdown
      pickupPoints={pickupPoints}
      source="pickupPoints"
      label={translate('promotion.filter.retailer')}
      selectedPickupPoints={selectedPickupPoints}
    />
    <DateInput source="startDateFrom" label={translate('promotion.filter.startDate')} />
    <DateInput source="startDateUntil" label={translate('promotion.filter.endDate')} />
  </Filter>
);


const rowClick = memoize(permissions => () => (permissions === 'admin'
  ? Promise.resolve('edit')
  : Promise.resolve('show')));

const PromotionList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const { basePath, location } = props;
  const [pickupPointsData, setPickupPointsData] = useState();
  const dataProvider = useDataProvider();
  const { search } = location;

  useEffect(() => {
    dataProvider.getList('pickupPoints',
      {
        pagination: {
          page: 1,
          perPage: 300,
        },
      })
      .then((data) => {
        setPickupPointsData(data?.data);
    });
    /* eslint-disable-next-line */
  }, []);

  const urlParams = new URLSearchParams(search);
  let selectedPickupPoints = [];
  if (urlParams.get('filter')?.length) {
    const parsedFilter = JSON.parse(urlParams.get('filter'));
    if (parsedFilter?.pickupPoints) {
      const { pickupPoints } = parsedFilter;
      selectedPickupPoints = pickupPoints;
    }
  }

  return (
    <List
      {...props}
      empty={<EmptyList />}
      hasCreate
      actions={<ListActions module="promotions" exporter={promotionExport} maxResults={null} />}
      filters={(
        <PromotionFilter
          translate={translate}
          permissions={permissions}
          pickupPoints={pickupPointsData}
          selectedPickupPoints={selectedPickupPoints}
        />
      )}
    >
      {useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
        <SimpleList
          rowClick={rowClick(permissions)}
          primaryText={record => record.name}
          data-testid="promotion-list-mobile-data-grid"
        />
      ) : (
        <CustomDatagrid
          data-testid="promotion-list-data-grid"
          rowClick="edit"
        >
          <LinkTextField basePath={basePath} permissions={permissions} source="key" label={translate('promotion.list.id')} />
          <EANListPopover label="EAN'S" source="eans" sortable={false} />
          <LinkTextField basePath={basePath} permissions={permissions} source="name" label={translate('promotion.list.name')} />
          <DateField source="startDate" locales="fr-FR" label={translate('promotion.list.startDate')} width="10%" />
          <PromotionStatusRollover label={translate('promotion.list.state')} width="3%" />
        </CustomDatagrid>
      )}
    </List>
  );
};

export default PromotionList;
