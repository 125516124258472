import React from 'react';

import { Datagrid, List, TextField } from 'react-admin';

const StaticList = props => (
  <List bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Title" sortable={false} />
      <TextField source="alias" sortable={false} />
      <TextField source="status" sortable={false} />
      <TextField source="position" label="Order" sortable={false} />
    </Datagrid>
  </List>
);

export default StaticList;
