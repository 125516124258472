import React, { useLayoutEffect, useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import {
  Loading, useDataProvider, useNotify,
} from 'react-admin';

import AddLocation from './AddLocation';
import ShowLocation from './ShowLocation';


const UserPickUpLocation = ({ record }) => {
  const [location, setLocation] = useState(null);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const showLocation = useMemo(() => location !== null, [location]);

  useLayoutEffect(() => {
    if (record) {
      dataProvider.getOne('userPickupPoints', {
        userId: record?.id,
      }).then((res) => {
        const { data: dataResult } = res;
        if (dataResult?.id) {
          return setLocation(dataResult);
        }
        return setLocation(null);
      }).catch((error) => {
        notify(error.message, 'error');
      });
    }
    /* eslint-disable-next-line */
  }, [record, dataProvider]);

  if (!record) {
    return <Loading />;
  }
  return (
    <Box sx={{ margin: '20px', boxSizing: 'border-box' }}>
      {!showLocation && <AddLocation record={record} setLocation={setLocation} />}
      {showLocation && location
        && (
        <ShowLocation
          point={location}
          record={record}
          setLocation={setLocation}
        />
        )}
    </Box>
  );
};

export default UserPickUpLocation;
