import React, { useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import { Notification, useNotify } from 'react-admin';
import { useTranslate } from 'ra-core';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

import { ReactComponent as LogoSVG } from '../../assets/images/logo.svg';
import { GRAPHQL_API_URL, TOKEN_URL } from '../../lib/utils';
import SEND_RESET_PASSWORD from '../../providers/mutations/sendResetPassword';

const EMAIL_MAX_LENGHT = 254;

const emailIsBadFormatted = (value) => {
  const emailFormat = new RegExp('^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$');

  if (value) {
    if (value.length >= EMAIL_MAX_LENGHT || emailFormat.test(value.trim()) === false) {
      return true;
    }
  }
  return false;
};

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);
const {
  Form,
} = withTypes();

const ForgotPass = (props) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(undefined);
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const { backLogin, isClick } = props;

  const defaultHandleSubmit = (auth) => {
    setLoading(true);
    const { username } = auth;
    setEmail(username);
  };

  const { onSubmit } = props;
  const handleSubmit = onSubmit || defaultHandleSubmit;

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }

    if (emailIsBadFormatted(values.username)) {
      errors.username = translate('ra.validation.email');
    }
    return errors;
  };

  const onClickBack = () => {
    isClick(true);
    backLogin('login');
  };
  useEffect(() => {
    const url = GRAPHQL_API_URL();
    const backUrl = TOKEN_URL();
    if (email && url) {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          query: SEND_RESET_PASSWORD,
          variables: {
            input: {
              landingPageUrl: `${backUrl}:token:`,
              username: email,
            },
          },
        }),
      })
        .then(res => res.json())
        .then((res) => {
          if (res?.data) {
            const { sendResetPassword } = res?.data;
            if (sendResetPassword) {
              setLoading(false);
              return notify(translate('app.forgot.success'), 'success');
            }
          }
          setLoading(false);
          return notify(translate('app.forgot.error'), 'warning');
        })
        .catch(() => {
          setLoading(false);
          return notify(translate('app.forgot.error'), 'warning');
        });
    }
    /* eslint-disable-next-line */
  }, [email]);
  /* eslint-disable no-shadow */
  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.header}>
                <div className={classes.logo}>
                  <div id="brand-logo-nav" align="center" spacingy="l2">
                    <LogoSVG />
                  </div>
                </div>
              </div>
              <div className={classes.form}>
                <Typography>{translate('app.forgot.claim')}</Typography>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="username"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('app.forgot.field')}
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="outlined"
                  type="submit"
                  color="primary"
                  disabled={loading}
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                    />
                  )}
                  {translate('app.forgot.submit')}
                </Button>
              </CardActions>
              <div className={classes.resetPassword}>
                {/* eslint-disable-next-line */}
                <span onClick={e => onClickBack(e)}>
                  {translate('app.back')}
                </span>
              </div>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

export default ForgotPass;
