import React, { useState, useEffect } from 'react';
import {
  useRedirect, useTranslate, useNotify, useDataProvider,
} from 'react-admin';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody,
  TableContainer,
  Button,
  CircularProgress,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

import UserPointsForm from './UserPointsForm';
import { ACTIONS } from './utils';

import userPointsTabStyles from './userPointsTabStyles';

const NO_ITEMS = 0;

const BUSINESS_DOMAIN = {
  PROMOTIONS: 'promotion',
};


const PAGINATION_DIFFERENCE = 1;
const ROWS_PER_PAGE = 10;
const ENTITY_INDEX = 0;
const TYPE_INDEX = 2;
const FIRST_PAGE = 1;

const SubstractButton = withStyles(theme => ({
  root: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}))(Button);

const UserPointsTab = ({ record }) => {
  const [list, setList] = useState(null);
  const [page, setPage] = useState(FIRST_PAGE);
  const [loading, setLoading] = useState(true);
  const [userPointsLoading, setUserPointsLoading] = useState(true);
  const [addDialogIsOpen, switchAddDialog] = useState(false);
  const [userPointsError, setUserPointsError] = useState(null);
  const [userPoints, setUserPoints] = useState();
  const [action, setAction] = useState();
  const [reload, setReload] = useState(false);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const translate = useTranslate();
  const classes = userPointsTabStyles();
  const notify = useNotify();

  useEffect(() => {
    if (record) {
      setLoading(true);

      dataProvider.getList('transactions', {
        userId: record.id,
        pagination: {
          page,
          perPage: 10,
        },
      }).then((data) => {
        setList(data);
        setLoading(false);
      }).catch((error) => {
        if (error) {
          const noData = {
            data: {
              pagination: {
                count: 0,
                pages: 1,
              },
              transactions: [],
            },
          };
          notify(`Sorry, there was an error: ${error} `, 'warning');
          setList(noData);
        }
      });
    }
    /* eslint-disable-next-line */
  }, [record, page, reload]);

  useEffect(() => {
    if (record) {
      setUserPointsLoading(true);
      dataProvider.getOne('points', {
        userId: record.id,
      }).then((data) => {
        setUserPoints(data?.data?.amount);
        setUserPointsLoading(false);
      }).catch((error) => {
        setUserPointsError(error);
      });
    }
    /* eslint-disable-next-line */
  }, [record]);

  const handleOnClickAddPoints = () => {
    setAction(ACTIONS.ADD);
    switchAddDialog(true);
  };

  const handleOnClickSubtractPoints = () => {
    setAction(ACTIONS.SUBSTRACT);
    switchAddDialog(true);
  };

  const redirectToPromotionItem = (entity, id) => {
    redirect(`/${entity}s/${id}`);
  };

  const handleOnPromotionIdPress = (e, entity, id) => {
    if (e.key === 'Enter') {
      redirect(`/${entity}s/${id}`);
    }
  };

  const renderPromotionConcept = (row) => {
    const data = row.concept.split(' ');
    const numberPattern = /-?\d+/gm;
    const promotionKey = row.concept.match(numberPattern);

    if (row.business_domain === BUSINESS_DOMAIN.PROMOTIONS && promotionKey) {
      return (
        <>
          <span>
            {data[ENTITY_INDEX]}
          </span>
          <span
            data-cy="tag-promotion-id"
            className={classes.link}
            tabIndex={0}
            role="button"
            onClick={() => redirectToPromotionItem(BUSINESS_DOMAIN.PROMOTIONS, promotionKey)}
            onKeyPress={e => handleOnPromotionIdPress(e, BUSINESS_DOMAIN.PROMOTIONS, promotionKey)}
          >
             #{promotionKey}
          </span>
          <span>
            {data[TYPE_INDEX]}
          </span>
        </>
      );
    }

    return row.concept;
  };

  const renderPoints = (type, points) => {
    if (type === ACTIONS.ADD) {
      return `+${points}`;
    }

    return `-${points}`;
  };

  const renderActions = () => {
    if (userPointsLoading) {
      return null;
    }

    if (userPointsError === null) {
      return (
        <div className={classes.actionsHeader}>
          <div className={classes.userPoints} data-cy="user-points">
            {record && typeof userPoints === 'number' ? translate(
              'points.list.userPoints',
              { user: `${record.name} ${record.lastName}`, points: userPoints },
            ) : null}
          </div>
          <div className={classes.actionsButtons}>
            <Tooltip
              arrow
              placement="top"
              title="This functionality is not yet enabled due to dependencies with Legacy"
            >
              <div className={classes.actionsButtons}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOnClickAddPoints()}
                  data-cy="add-points-button"
                  disabled
                >
                  {translate('points.actions.add')}
                </Button>
                <SubstractButton
                  variant="contained"
                  className={classes.deleteButton}
                  onClick={() => handleOnClickSubtractPoints()}
                  data-cy="substract-points-button"
                  disabled
                >
                  {translate('points.actions.subtract')}
                </SubstractButton>
              </div>
            </Tooltip>
            <UserPointsForm
              action={action}
              isOpen={addDialogIsOpen}
              switchDialog={switchAddDialog}
              record={record}
              reload={reload}
              setReload={setReload}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  const renderTable = () => {
    if (list?.total > NO_ITEMS) {
      return (
        <div>
          <TableContainer component={Paper}>
            <Table aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>{translate('points.list.concept')}</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{translate('points.list.date')}</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{translate('points.list.number')}</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.data?.map(transaction => (
                  <TableRow>
                    <TableCell align="left">
                      {renderPromotionConcept(transaction)}
                    </TableCell>
                    <TableCell align="center">
                      {moment(transaction.transactionDate).local().format('DD/MM/YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell align="center">
                      {renderPoints(transaction.type, transaction.amount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={list?.total}
            page={page - PAGINATION_DIFFERENCE}
            rowsPerPage={ROWS_PER_PAGE}
            rowsPerPageOptions={[]}
            onPageChange={(e, newPage) => setPage(newPage + PAGINATION_DIFFERENCE)}
          />
        </div>
      );
    }

    return (
      <div className={classes.noPointsMessage}>
        <span>No results found</span>
        <br />
        <span>{userPointsError || null}</span>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      {renderActions()}
      {
        loading ? (
          <div className={classes.loading}>
            <CircularProgress
              size={25}
              thickness={2}
            />
          </div>
        ) : renderTable()
      }
    </div>
  );
};

export default UserPointsTab;
