export const validateStaticContentForm = (values) => {
  const errors = {};

  if (!values?.name) {
    errors.name = 'ra.validation.required';
  }

  if (!values?.alias) {
    errors.alias = 'ra.validation.required';
  }

  if (!values?.alias?.match(/[a-zA-Z0-9-]$/)) {
    errors.alias = 'Must have only alphanumeric characters, and -. Spaces and special characters are not allowed';
  }

  if (!values?.status) {
    errors.status = 'ra.validation.required';
  }

  if (values?.status === 'PUBLISHED') {
    if (!values?.position) {
      errors.position = 'ra.validation.required';
    }

    if (isNaN(values?.position)) {
      errors.position = 'ra.validation.number';
    }
  }

  return errors;
};
