import React, { useEffect, useMemo, useState } from 'react';
import { useNotify, useRefresh } from 'ra-core';
import {
  useTranslate,
  FormWithRedirect,
  Button,
  CheckboxGroupInput,
  useDataProvider,
  RadioButtonGroupInput,
} from 'react-admin';
import {
  Box,
  Toolbar,
  Typography,
  Switch,
  CircularProgress,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import { promotionFormStyles, StyledRefreshIcon, StyledSlider } from './promotionFormStyles';

import { RADIX_TEN, SEGMENTATION_GENDERS } from './constants';
import { ERROR_TEXT } from '../retailers/constants';
import { petsOptions } from '../../lib/utils/profile';

const PromotionSegmentation = (props) => {
  const zero = 0;
  const maxAgeValue = 65;
  const maxInternal = 66;
  const { promotionUuid } = props;
  const t = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const classes = promotionFormStyles();
  const limit = 9;
  const page = 1;
  const [genderValues, setGenderValues] = useState(null);
  const [ageRangeValues, setAgeRangeValues] = useState(null);
  const [ageRangeError, setAgeRangeError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [updateTargetFlag, setUpdateTargetFlag] = useState(false);
  const [withChildrenValue, setWithChildrenValue] = useState(null);
  const [underAgeValue, setUnderAgeValue] = useState(null);
  const [childrenAgeValues, setChildrenAgeValues] = useState(null);
  const [minChildrenValue, setMinChildrenValue] = useState(zero);
  const [maxChildrenValue, setMaxChildrenValue] = useState(maxInternal);
  const [withPetsValue, setWithPetsValue] = useState(null);
  const [petsValues, setPetsValues] = useState(null);
  const [preferenceAlcoholValues, setPreferenceAlcoholValues] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState(null);
  const [target, setTarget] = useState('-');
  const [switchGenders, setSwitchGenders] = useState(false);
  const [switchAgeRange, setSwitchAgeRange] = useState(false);
  const [switchChildren, setSwitchChildren] = useState(false);
  const [switchPets, setSwitchPets] = useState(false);
  const [switchPreferenceAlcohol, setswitchPreferenceAlcohol] = useState(false);

  const record = useMemo(() => ({
    id: 'segmentations',
    genders: data?.genders ?? null,
    ageRange: data?.ageRange ?? null,
    children: data?.children ?? null,
    pets: data?.pets ?? null,
    preferenceAlcohol: data?.preferenceAlcohol ?? null,
  }), [data]);

  const isAgeActivatedWithError = () => switchAgeRange && ageRangeError;

  const handleChangeGenders = (value) => {
    const newValue = (Array.isArray(value) && value.length === zero) ? null : value;
    setGenderValues(newValue);
  };

  const getGendersValues = () => {
    if (switchGenders
      && genderValues !== null
      && genderValues?.length !== SEGMENTATION_GENDERS.length) {
      return genderValues;
    }

    return null;
  };

  const handleChangeAgeRange = (event, value) => {
    if (ageRangeError) {
      setAgeRangeError(false);
    }

    const from = value[0];
    const to = value[1];

    setAgeRangeValues([from, to]);
  };

  const getAgeRangeValues = () => {
    if (switchAgeRange) {
      const gte = ageRangeValues[0];
      const lte = ageRangeValues[1] > maxAgeValue ? null : ageRangeValues[1];

      return {
        gte,
        lte,
      };
    }

    return null;
  };

  const handleChangeChildrenAgeRange = (event, value) => {
    const from = value[0];
    const to = value[1];

    setChildrenAgeValues([from, to]);
  };

  const hasChildrenErrors = () => (
    withChildrenValue === true && (errors?.underAgeValue || errors?.childrenSlider)
  );
  const hasPetsErrors = () => withPetsValue === true && errors?.petTypes;

  const validateChildren = () => {
    if (withChildrenValue === null) {
      return true;
    }
    let output = true;
    let object = errors;

    if (withChildrenValue === true) {
      if (underAgeValue === null) {
        object = { ...object, underAgeValue: true };
        output = false;
      }

      if (underAgeValue === true) {
        const check = childrenAgeValues[0] === null || childrenAgeValues[1] === null;
        object = { ...object, childrenSlider: check };

        if (check) {
          output = false;
        }
      }

      if (underAgeValue === false) {
        const check = childrenAgeValues[0] === null && childrenAgeValues[1] === null;
        object = { ...object, childrenSlider: check };

        if (check) {
          output = false;
        }
      }

      setErrors(object);
    }

    return output;
  };

  const getChildrenNodeValue = () => {
    if (withChildrenValue === null || !switchChildren) {
      return null;
    }

    if (withChildrenValue === false) {
      return {
        has: false,
      };
    }

    let gte;
    let lte;

    if (underAgeValue === true) {
      gte = `P${childrenAgeValues[0]}M`;
      lte = `P${childrenAgeValues[1]}M`;
    }

    if (underAgeValue === false) {
      gte = `P${childrenAgeValues[0]}Y`;
      lte = childrenAgeValues[1] > maxAgeValue ? null : `P${childrenAgeValues[1]}Y`;
    }

    return {
      ageRange: {
        gte,
        lte,
      },
      has: true,
    };
  };

  const handleChangePets = (value) => {
    const newValue = (Array.isArray(value) && value.length === zero) ? null : value;
    setPetsValues(newValue);
  };

  const validatePets = () => {
    if (withPetsValue === null) {
      return true;
    }

    let object = errors;

    if (withPetsValue === true) {
      if (petsValues === null || (Array.isArray(petsValues) && petsValues.length === zero)) {
        object = { ...object, petTypes: true };
        setErrors(object);

        return false;
      }
    }

    return true;
  };

  const getPetsNodeValue = () => {
    if (switchPets) {
      if (withPetsValue === false) {
        return {
          has: false,
        };
      }

      if (withPetsValue === true) {
        return {
          has: true,
          petTypes: petsValues,
        };
      }
    }

    return null;
  };

  const getPreferenceAlcoholNodeValue = () => {
    if (switchPreferenceAlcohol) {
      return preferenceAlcoholValues === true;
    }
    return false;
  };

  const handleChangePreferenceAlcohol = (value) => {
    const newValue = (typeof value === 'boolean') ? value : null;
    setPreferenceAlcoholValues(newValue);
    setswitchPreferenceAlcohol(newValue);
  };

  const getSegmentationTarget = () => {
    const ageRange = getAgeRangeValues();
    const children = getChildrenNodeValue();
    const genders = getGendersValues();
    const pets = getPetsNodeValue();
    const preferenceAlcohol = getPreferenceAlcoholNodeValue();

    dataProvider.getManyReference('segmentations', {
      target: {
        ageRange,
        children,
        genders,
        pets,
        preferenceAlcohol,
      },
    })
      .then((res) => {
        const { total } = res;

        setTarget(`${total}`);
        setUpdateTargetFlag(false);
      })
      .catch(() => setUpdateTargetFlag(false));
  };

  useEffect(() => {
    if (updateTargetFlag) {
      getSegmentationTarget();
    }
    /* eslint-disable-next-line */
  }, [updateTargetFlag]);

  useEffect(() => {
    dataProvider.getOne('segmentations', {
      pagination: {
        limit,
        page,
      },
      filter: {
        promotionIds: [promotionUuid],
      },
    })
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        notify(error?.message, 'error');
        setLoading(false);
      });
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (record) {
      if (record?.genders) {
        setSwitchGenders(true);
        setGenderValues(record?.genders);
      }

      if (record?.ageRange) {
        setSwitchAgeRange(true);
        setAgeRangeValues([record?.ageRange?.gte, record?.ageRange?.lte || maxInternal]);
      } else {
        setSwitchAgeRange(false);
        setAgeRangeValues([null, null]);
      }

      if (record?.children) {
        setSwitchChildren(true);
        const hasChildren = (record?.children?.has === true
          || record?.children?.has === false) ? record?.children?.has : null;
        setWithChildrenValue(hasChildren);

        if (record?.children?.has) {
          const slice = -1;
          const from = record?.children?.ageRange?.gte;
          const to = record?.children?.ageRange?.lte;

          const parsedFrom = parseInt(from.replace('P', '').slice(zero, slice), RADIX_TEN);
          const parsedTo = to ? parseInt(to.replace('P', '').slice(zero, slice), RADIX_TEN) : maxInternal;

          if (from.slice(slice) === 'M') {
            setUnderAgeValue(true);
          }
          if (from.slice(slice) === 'Y') {
            setUnderAgeValue(false);
          }

          setChildrenAgeValues([parsedFrom, parsedTo]);
        }
      }

      if (record?.pets) {
        setSwitchPets(true);
        setWithPetsValue(record?.pets?.has || null);
        setPetsValues(record?.pets?.petTypes || null);
      }

      if (record?.preferenceAlcohol) {
        setswitchPreferenceAlcohol(true);
        setPreferenceAlcoholValues(record?.preferenceAlcohol || null);
      }

      if (record?.ageRange || record?.children || record?.pets || record?.preferenceAlcohol) {
        setUpdateTargetFlag(true);
      }
    }
  }, [record]);

  useEffect(() => {
    if (underAgeValue === true) {
      const min = 0;
      const max = 36;
      setMinChildrenValue(min);
      setMaxChildrenValue(max);
    } else if (underAgeValue === false) {
      const min = 0;
      setMinChildrenValue(min);
      setMaxChildrenValue(maxInternal);
    }
  }, [underAgeValue]);

  useEffect(() => {
    setErrors({});
  }, [underAgeValue,
    childrenAgeValues,
    withChildrenValue,
    petsValues,
    withPetsValue,
    preferenceAlcoholValues,
  ]);

  useEffect(() => {
    if (!switchGenders) {
      setGenderValues(null);
    }

    if (!switchAgeRange) {
      setAgeRangeValues([null, null]);
    }

    if (!switchChildren) {
      setWithChildrenValue(null);
      setChildrenAgeValues([null, null]);
      setUnderAgeValue(null);
    }

    if (!switchPets) {
      setWithPetsValue(null);
      setPetsValues(null);
    }
  }, [switchGenders, switchAgeRange, switchChildren, switchPets]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingUpdate(true);
    const childrenValidation = validateChildren();
    const petsValidation = validatePets();

    if (switchAgeRange && ageRangeValues[0] === null && ageRangeValues[1] === null) {
      setAgeRangeError(true);
      setLoadingUpdate(false);
    } else if (childrenValidation === false || petsValidation === false) {
      setLoadingUpdate(false);
    } else {
      const genders = getGendersValues();
      const ageRange = getAgeRangeValues();
      const children = getChildrenNodeValue();
      const pets = getPetsNodeValue();
      const preferenceAlcohol = getPreferenceAlcoholNodeValue();
      dataProvider.update('segmentations', {
        updateSegmentationId: data?.id,
        input: {
          genders,
          ageRange,
          children,
          pets,
          preferenceAlcohol,
        },
      })
        .then(() => {
          notify(t('promotion.segmentation.updateSuccess'), 'success');
          setLoadingUpdate(false);
          refresh();
        })
        .catch((error) => {
          notify(error?.message, 'error');
          setLoadingUpdate(false);
        });
    }
  };

  const valueLabelFormat = value => (value > maxAgeValue ? '65+' : value);

  const valueLabelFormatChildren = (value) => {
    if (underAgeValue) {
      return `${value}m`;
    }

    return value > maxAgeValue ? '65+y' : `${value}y`;
  };

  return (
    <div className={classes.formControl}>
      <FormWithRedirect
        redirect={false}
        record={record}
        {...props}
        render={() => (
          <form style={{ width: '100%' }} onSubmit={handleSubmit}>
            <Box className={classes.sectionWrapper}>
              <Box className={classes.sectionTitleWrap}>
                <Typography variant="h2">{t('promotion.segmentation.genders')}</Typography>
                <Switch
                  checked={switchGenders}
                  onChange={event => setSwitchGenders(event.target.checked)}
                  color="primary"
                />
              </Box>
              <CheckboxGroupInput
                source="genders"
                className={classes.hideHelperText}
                choices={SEGMENTATION_GENDERS}
                alwaysOn
                disabled={!switchGenders || loading || loadingUpdate}
                onChange={e => handleChangeGenders(e)}
                label=""
              />
            </Box>
            <Box className={classes.sectionWrapper}>
              <Box className={classes.sectionTitleWrap}>
                <Typography variant="h2">{t('promotion.segmentation.ageRange')}</Typography>
                <Switch
                  checked={switchAgeRange}
                  onChange={event => setSwitchAgeRange(event.target.checked)}
                  color="primary"
                />
              </Box>
              <Box className={classes.ageRangeContainer}>
                <Box className={classes.sliderContainer}>
                  <StyledSlider
                    defaultValue={[null, null]}
                    step={1}
                    min={18}
                    max={66}
                    valueLabelFormat={valueLabelFormat}
                    valueLabelDisplay="on"
                    disabled={!switchAgeRange || loading || loadingUpdate}
                    onChange={handleChangeAgeRange}
                    value={ageRangeValues}
                    color={isAgeActivatedWithError() ? 'secondary' : 'primary'}
                  />
                  {isAgeActivatedWithError() && (
                    <Box>
                      <Typography variant="subtitle1" color="error" className={classes.errorText}>
                        {t('promotion.segmentation.ageRangeError')}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box className={classes.sectionWrapper}>
              <Box className={classes.sectionTitleWrap}>
                <Typography variant="h2">{t('promotion.segmentation.children')}</Typography>
                <Switch
                  checked={switchChildren}
                  onChange={event => setSwitchChildren(event.target.checked)}
                  color="primary"
                />
              </Box>
              <Box className={classes.hideHelperText}>
                <RadioButtonGroupInput
                  source="children.has"
                  label=""
                  choices={[
                    { id: true, name: t('promotion.segmentation.withChildren') },
                    { id: false, name: t('promotion.segmentation.withoutChildren') },
                  ]}
                  initialValue={withChildrenValue}
                  value={withChildrenValue}
                  onChange={setWithChildrenValue}
                  disabled={!switchChildren || loading || loadingUpdate}
                />
              </Box>
              {withChildrenValue === true && (
                <Box>
                  <Typography>{t('promotion.segmentation.questionChildrenAge')}</Typography>
                  <Box>
                    <RadioButtonGroupInput
                      source="underAge"
                      label=""
                      choices={[
                        { id: true, name: t('ra.boolean.true') },
                        { id: false, name: t('ra.boolean.false') },
                      ]}
                      onChange={setUnderAgeValue}
                      initialValue={underAgeValue}
                      value={underAgeValue}
                      disabled={loading || loadingUpdate}
                    />
                    {hasChildrenErrors() && errors?.underAgeValue && (
                      <Box>
                        <Typography variant="subtitle1" color="error" className={classes.errorText}>
                          {ERROR_TEXT}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  {underAgeValue !== null && (
                    <Box className={classes.childrenAgeRangeContainer}>
                      <Box className={classes.sliderContainer}>
                        <StyledSlider
                          defaultValue={[null, null]}
                          step={1}
                          min={minChildrenValue}
                          max={maxChildrenValue}
                          valueLabelFormat={valueLabelFormatChildren}
                          valueLabelDisplay="on"
                          disabled={loading || loadingUpdate}
                          onChange={handleChangeChildrenAgeRange}
                          value={childrenAgeValues}
                          color={hasChildrenErrors() && errors?.childrenSlider ? 'secondary' : 'primary'}
                        />
                        {hasChildrenErrors() && errors?.childrenSlider && (
                          <Box>
                            <Typography variant="subtitle1" color="error" className={classes.errorText}>
                              {t('promotion.segmentation.childrenRangeError')}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Box className={classes.sectionWrapper}>
              <Box className={classes.sectionTitleWrap}>
                <Typography variant="h2">{t('promotion.segmentation.pets')}</Typography>
                <Switch
                  checked={switchPets}
                  onChange={event => setSwitchPets(event.target.checked)}
                  color="primary"
                />
              </Box>
              <Box className={classes.hideHelperText}>
                <RadioButtonGroupInput
                  source="pets.has"
                  label=""
                  choices={[
                    { id: true, name: t('promotion.segmentation.withPets') },
                    { id: false, name: t('promotion.segmentation.withoutPets') },
                  ]}
                  initialValue={withPetsValue}
                  value={withPetsValue}
                  onChange={setWithPetsValue}
                  disabled={!switchPets || loading || loadingUpdate}
                />
              </Box>
              {withPetsValue === true && (
                <>
                  <CheckboxGroupInput
                    source="pets.petTypes"
                    className={classes.hideHelperText}
                    choices={petsOptions(t)}
                    alwaysOn
                    disabled={loading || loadingUpdate}
                    onChange={e => handleChangePets(e)}
                    initialValue={petsValues}
                    value={petsValues}
                    label=""
                  />
                  {hasPetsErrors() && (
                    <Box>
                      <Typography variant="subtitle1" color="error" className={classes.errorText}>
                        {t('promotion.segmentation.petsError')}
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
            <Box className={classes.sectionWrapper}>
              <Box className={classes.sectionTitleWrap}>
                <Typography variant="h2">{t('promotion.segmentation.alcohol')}</Typography>
                <Switch
                  checked={switchPreferenceAlcohol}
                  onChange={event => handleChangePreferenceAlcohol(event.target.checked)}
                  color="primary"
                />
              </Box>
            </Box>
            <Box className={classes.sectionWrapper}>
              <Box className={classes.sectionTitleWrap}>
                <Typography>{t('promotion.segmentation.target')} {target} </Typography>
                {updateTargetFlag
                  ? <CircularProgress size={20} thickness={1} />
                  : <StyledRefreshIcon onClick={() => setUpdateTargetFlag(true)} />
                }
              </Box>
            </Box>
            <Toolbar>
              <Box display="flex">
                <Button
                  label="Save"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    loading
                    || loadingUpdate
                    || isAgeActivatedWithError()
                    || hasChildrenErrors()
                    || hasPetsErrors()
                  }
                  startIcon={<SaveIcon />}
                />
              </Box>
            </Toolbar>
          </form>
        )}
      />
    </div>
  );
};

export default PromotionSegmentation;
