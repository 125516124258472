import React, { useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import { Notification, useNotify } from 'react-admin';
import { useTranslate } from 'ra-core';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

import { ReactComponent as LogoSVG } from '../../assets/images/logo.svg';
import { GRAPHQL_API_URL } from '../../lib/utils';
import RESET_PASSWORD from '../../providers/mutations/resetPassword';


const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);
const {
  Form,
} = withTypes();

const CheckToken = (props) => {
  const [loading, setLoading] = useState(false);
  const [pass, setPass] = useState(undefined);
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const { backLogin, checkToken, isClick } = props;
  const defaultHandleSubmit = (auth) => {
    setLoading(true);
    const { password } = auth;
    setPass(password);
  };

  const { onSubmit } = props;
  const handleSubmit = onSubmit || defaultHandleSubmit;

  const validate = (values) => {
    const errors = {};
    const regexToTest = new RegExp('^(?=(.*\\d))(?=.*[a-z])(?=.*[A-Z]).{8,255}$');
    if (values) {
      if (!values.password) {
        errors.password = translate('ra.validation.required');
        return errors;
      }
      if (values.password && !regexToTest.test(values.password)) {
        errors.password = translate('app.check.errorFormat');
        return errors;
      }
      if (!values.password2) {
        errors.password2 = translate('ra.validation.required');
        return errors;
      }
      if (values.password2 && !regexToTest.test(values.password2)) {
        errors.password2 = translate('app.check.errorFormat');
        return errors;
      }
      if (values.password !== values.password2) {
        errors.password2 = translate('app.check.errorSame');
        return errors;
      }
    }
    return {};
  };

  const onClickBackPage = () => {
    isClick(true);
    backLogin('login');
  };
  useEffect(() => {
    const url = GRAPHQL_API_URL();
    if (pass && url) {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          query: RESET_PASSWORD,
          variables: {
            password: pass,
            token: checkToken,
          },
        }),
      })
        .then(res => res.json())
        .then((res) => {
          if (res?.data) {
            const { resetPassword } = res?.data;
            if (resetPassword) {
              setLoading(false);
              return notify(translate('app.check.success'), 'success');
            }
          }
          setLoading(false);
          return notify(translate('app.check.error'), 'warning');
        })
        .catch(() => {
          setLoading(false);
          return notify(translate('app.check.error'), 'warning');
        });
    }
    /* eslint-disable-next-line */
  }, [pass]);
  /* eslint-disable no-shadow */
  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.header}>
                <div className={classes.logo}>
                  <div id="brand-logo-nav" align="center" spacing="l2">
                    <LogoSVG />
                  </div>
                </div>
              </div>
              <div className={classes.form}>
                <Typography>{translate('app.check.claim')}</Typography>
                <div className={classes.input}>
                  <Field
                    name="password"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('app.check.field')}
                    type="password"
                    disabled={loading}
                  />
                </div>
              </div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    name="password2"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('app.check.field2')}
                    type="password"
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="outlined"
                  type="submit"
                  color="primary"
                  disabled={loading}
                >
                  {loading && (
                    <CircularProgress
                      size={24}
                      thickness={2}
                    />
                  )}
                  {translate('app.check.submit')}
                </Button>
              </CardActions>
              <div className={classes.resetPassword}>
                {/* eslint-disable-next-line */}
                <span onClick={e => onClickBackPage(e)}>
                  {translate('app.back')}
                </span>
              </div>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

export default CheckToken;
