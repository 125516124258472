import { Dialog } from '@material-ui/core';
import React, { Fragment, useCallback } from 'react';
import {
  Datagrid,
  List,
  TextField,
  FunctionField,
  DateField,
  useTranslate,
} from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { ListActions } from '../../lib/components';
import { commentListStyles } from './styles';
import CommentEdit from './CommentEdit';
import PaginationComment from './PaginationComment';
import CommentFilter from './CommentFilter';
import ReviewsStatusSelect from '../../lib/components/ReviewStatusSelect';

const MAX_LIMIT_EXPORT = 15000;

const CommentList = ({ permissions, ...props }) => {
  const history = useHistory();
  const classes = commentListStyles();
  const translate = useTranslate();
  const EditPanel = ({ id }) => (
    <CommentEdit onCancel={() => {}} id={id} {...props} />
  );
  const handleClose = useCallback(() => false, []);

  return (
    <Fragment>
      <List
        {...props}
        actions={<ListActions hasCreate={false} maxResults={MAX_LIMIT_EXPORT} />}
        filters={<CommentFilter translate={translate} permissions={permissions} />}
        sort={{
          field: 'createdAt',
          order: 'DESC',
        }}
        pagination={<PaginationComment />}
        bulkActionButtons={false}
        empty={false}
      >
        {(
          <Datagrid
            expand={<EditPanel />}
            classes={{
              headerRow: classes.headerRow,
              headerCell: classes.headerCell,
              rowCell: classes.rowCell,
              expandIconCell: classes.expandedCell,
              expandHeader: classes.expandedCell,
            }}
            optimized
            data-testid="comment-list-data-grid"
          >
            <TextField source="author.email" cellClassName={classes.leftCell} label={translate('comment.list.author')} sortable={false} />
            <TextField source="ean" cellClassName={classes.centerCell} label={translate('comment.list.ean')} sortable={false} />
            <TextField source="text" cellClassName={classes.comment} label={translate('comment.list.text')} sortable={false} />
            <TextField source="rating" cellClassName={classes.smallCell} label={translate('comment.list.rating')} sortable={false} />
            <FunctionField
              source="status"
              cellClassName={classes.statusCell}
              headerClassName={classes.centerCell}
              render={record => <ReviewsStatusSelect record={record} />}
              label={translate('comment.list.status')}
              sortable={false}
            />
            <DateField source="createdAt" cellClassName={classes.centerCell} headerClassName={classes.centerCell} label={translate('comment.list.createdAt')} />
          </Datagrid>
        )}
      </List>
      <Route path="/comments/:id">
        {
          ({ match }) => {
            const isMatch = !!(match
              && match.params
              && match.params.id !== 'create');

            return (
              <Dialog
                open={isMatch}
                onClose={() => { history.push('/comments'); }}
                scroll="paper"
                fullWidth
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {isMatch
                  ? <CommentEdit onCancel={handleClose} id={match.params.id} {...props} /> : null}
              </Dialog>
            );
          }
        }
      </Route>
    </Fragment>
  );
};

export default CommentList;
