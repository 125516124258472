import React, { useEffect, useState } from 'react';
import { useTranslate } from 'ra-core';
import { Loading, useDataProvider } from 'react-admin';
import moment from 'moment';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  TableBody,
  Button, TablePagination,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

import { StyledTableRow } from '../promotions/promotionFormStyles';
import UserAdditionalPointsForm from './UserAdditionalPointsForm';

const UserAdditionalPointsTab = ({ record }) => {
  const zero = 0;
  const perPage = 10;
  const PAGINATION_DIFFERENCE = 1;
  const ROWS_PER_PAGE = 10;
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addDialogIsOpen, switchAddDialog] = useState(false);
  const [reload, setReload] = useState(true);
  const [page, setPage] = useState(PAGINATION_DIFFERENCE);

  const renderStatusByDates = (startDate, endDate) => {
    const momentStart = moment(startDate).local();
    const momentEnd = moment(endDate).local();
    const momentNow = moment();

    if (momentNow.isAfter(momentEnd)) {
      return translate('user.additionalPoints.status.expired');
    }

    if (momentNow.isAfter(momentStart) && momentNow.isBefore(momentEnd)) {
      return translate('user.additionalPoints.status.available');
    }

    return translate('user.additionalPoints.status.programmed');
  };

  useEffect(() => {
    if (record?.id && reload && !loading) {
      setLoading(true);
      setReload(false);
      dataProvider.getList('points', {
        userId: record.id,
        pagination: {
          page,
          perPage,
        },
      }).then((dataThen) => {
        setData(dataThen);
        setLoading(false);
      }).catch((error) => {
        setData(error?.message);
        setLoading(false);
      });
    }
    /* eslint-disable-next-line */
  }, [record, reload]);

  useEffect(() => {
    if (page && !reload) {
      setReload(true);
    }
    /* eslint-disable-next-line */
  }, [page]);

  return (
    <Box sx={{ margin: '20px', boxSizing: 'border-box' }}>
      <Box display="flex" flexDirection="row nowrap" alignContent="space-between" alignItems="center">
        <Box flex={1}>
          <Typography variant="h2" component="h2">
            {translate('user.additionalPoints.title')}
          </Typography>
        </Box>
        <Box flex={1} alignSelf="end">
          <Tooltip
            arrow
            placement="left"
            title="This functionality is not yet enabled due to dependencies with Legacy"
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => switchAddDialog(true)}
                data-cy="add-additional-points-button"
                disabled
              >
                {translate('points.actions.add')}
              </Button>
            </div>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ marginTop: '35px' }}>
        {(loading || data === null) && <Loading />}
        {!loading && data?.total === zero && (
          <Typography>{translate('user.additionalPoints.noResults')}</Typography>
        )}
        {!loading && data?.data?.length > zero && (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Concept</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Start date</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>End date</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.map(item => (
                    <StyledTableRow key={item.id}>
                      <TableCell>{item?.name}</TableCell>
                      <TableCell>{moment(item.startDateAt).local().format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{moment(item.endDateAt).local().format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{item.amount}</TableCell>
                      <TableCell>{renderStatusByDates(item.startDateAt, item.endDateAt)}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={data?.total}
              page={page - PAGINATION_DIFFERENCE}
              rowsPerPage={ROWS_PER_PAGE}
              rowsPerPageOptions={[]}
              onPageChange={(e, newPage) => setPage(newPage + PAGINATION_DIFFERENCE)}
            />
          </>
        )}
      </Box>
      <UserAdditionalPointsForm
        action="add"
        isOpen={addDialogIsOpen}
        switchDialog={switchAddDialog}
        record={record}
        setReload={setReload}
      />
    </Box>
  );
};

export default UserAdditionalPointsTab;
